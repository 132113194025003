import React from 'react';
import { Grid, TextField, RadioGroup, Radio, FormControlLabel, FormControl, FormGroup, FormLabel, FormHelperText, Button } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import {updateAction} from "../../actions";
import { useForm, Controller } from 'react-hook-form';

interface IFormProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Section3: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const { register, handleSubmit, getValues, watch, control, formState: { errors }} = useForm({
    defaultValues: state?.formDetails?.mentor,
  });
  const classes = useStyles();
  const watchEnjoyment = watch('enjoyment');
  const watchExpertise = watch('expertise');
  const watchAvailability = watch('availability');

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({
      formDetails: {
        mentor: partialData
      } 
    } as any);    
    setStep(step -1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({
      formDetails: {
        mentor: data
      } 
    } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>We believe everyone can be a mentor and we welcome first time mentors. Please share if you have any prior experience (in number of years). *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup className={classes.horizontalRadioGroup} row {...field}>
                  {['0', '1-3', '4-6', '7-9', '10+'].map((experience, index) => (
                    <FormControlLabel key={index} value={experience} control={<Radio color='primary' />} label={experience} labelPlacement='bottom' />
                  ))}
                </RadioGroup>
              )}
              name='experience'
              control={control}
              rules={ { required: true } }
            />
            {errors?.experience?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.formLabel}>What do you enjoy most about being a mentor? *</FormLabel>
            <FormGroup row>        
              {['Growing my network', 'Further developing leadership skills', 'Sharing information', 'Gaining self fulfilment and personal growth', 'Gaining new perspectives from mentee', 'Inspiring and influencing new generations of entrepreneurs', 'Helping advance women in their careers', 'Other'].map((enjoyment, index) => 
                  <Grid className={classes.checkboxContainer} item xs={12} key={enjoyment}>
                    <FormLabel className={classes.checkboxLabel} key={index}>
                      <input className={classes.checkbox} {...register('enjoyment', { required: true })} type='checkbox' value={enjoyment} name='enjoyment'/>
                        {enjoyment}
                      </FormLabel>
                  </Grid>
              )}
            </FormGroup>
            {(errors.enjoyment as any)?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
          { watchEnjoyment && watchEnjoyment.includes('Other') &&
            <Grid item xs={12}>
              <Controller
                render={({ field }) => 
                  <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                }
                name="otherEnjoyment"
                control={control}
              />
            </Grid>
          }
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.formLabel}>Which of the following best describes your area of expertise in helping women? *</FormLabel>
            <FormGroup row>        
              {['Advancing careers', 'Navigating boundaries in the workplace', 'Negotiating for what women want', 'Finding their voice and speaking up', 'Improving public speaking', 'Stepping into leadership', 'Balancing work and life', 'Building a meaningful network', 'Building influence and confidence', 'Managing emotions in the workplace', 'Navigating Imposter Syndrome', 'Conflict Management', 'Navigating discrimination and stereotypes', 'Other'].map((expertise, index) => 
                  <Grid className={classes.checkboxContainer} item xs={12} key={index}>
                    <FormLabel className={classes.checkboxLabel} key={expertise}>
                      <input className={classes.checkbox} {...register('expertise', { required: true })} type='checkbox' value={expertise} name='expertise'/>
                      {expertise}
                    </FormLabel>
                  </Grid>
              )}
            </FormGroup>
            {(errors.expertise as any)?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
          { watchExpertise && watchExpertise.includes('Other') &&
            <Grid item xs={12}>
              <Controller
                render={({ field }) => 
                  <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                }
                name="otherExpertise"
                control={control}
              />
            </Grid>
          }
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>What are your goals as a mentor? *</FormLabel>
            <Controller
              render={({ field }) => 
                <TextField {...field} placeholder='Your answer' fullWidth variant='outlined'/>
              }
              name='goal'
              control={control}
              rules={ { required: true } }
            />
            {errors?.goal?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.formLabel}>Mentorship Style (Personality Type) *</FormLabel>
            <FormGroup row>        
            {['Motivational', 'Upbeat', 'Calm', 'Direct', 'Realist', 'Compassionate', 'Extroverted', 'Introverted'].map((personality, index) => 
                  <Grid className={classes.checkboxContainer} item xs={12} md={6} key={index}>
                    <FormLabel className={classes.checkboxLabel} key={personality}>
                      <input className={classes.checkbox} {...register('personality', { required: true })} type='checkbox' value={personality} name='personality'/>
                      {personality}
                    </FormLabel>
                  </Grid>
              )}
            </FormGroup>
            {(errors.personality as any)?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>Assuming your first session is a success, which of the following best describes your availability for future 20-30 min sessions *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['Up to 2 hours per month (~ 1 session per week)', 'Up to 1 hour per month (~ 1 session every 2 weeks)', 'Other'].map((availability, index) => 
                    <FormControlLabel key={index} value={availability} control={<Radio color='primary' />} label={availability}/>
                  )}
                </RadioGroup>
              )}
              name='availability'
              control={control}
              rules={ { required: true } }
            />
            {errors?.availability?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
          { watchAvailability && watchAvailability.includes('Other') &&
            <Grid item xs={12}>
              <Controller
                render={({ field }) => 
                  <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                }
                name="otherAvailability"
                control={control}
              />
            </Grid>
          }
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant='outlined' color='secondary' onClick={() => handleBack()}>BACK</Button>
        </Grid>
                  
        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant='contained' color='secondary' type='submit'>NEXT</Button>
        </Grid>        
      </Grid>
    </form>
  );
};

export default Section3;
