import { Button, CircularProgress, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentSurveys, getSurveys } from '../../../redux/actions/surveys';
import { useParams } from 'react-router-dom';
import { Session } from '../../../models/session';
import { sendFinalSurveysResponse } from '../../../redux/actions/surveysResponse';
import { useAuth0 } from '@auth0/auth0-react';
import { getInternalUser } from '../../../redux/actions/user';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  clientId: string;
  currentSession: Session;
  handleClose: () => void;
}

const useStyles = makeStyles({
  formLabel: {
    marginTop: '25px',
    marginBottom:'15px',
    fontWeight: 'bold',
    color: '#4c2525',
  },
  checkbox: {color: '#000000', fontSize: '18px',fontStyle: 'normal', fontWeight: 300 },
  errorText: {
    color: 'red'
  }
});

function FinalFeedbackForm(props: Props) {

  // const { state, actions } = useStateMachine({
  //   updateAction
  // });
  
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const classes = useStyles();
  const  {clientId}  = useParams<{clientId: string}>();
  const dispatch = useDispatch();

  const { register, handleSubmit, getValues, watch, control, formState: { errors }} = useForm();

  const watchThingsImproved = watch('thingsImproved');
  const watchImprovement = watch('improvement');
  const watchOpportunity = watch('opportunity');
  const watchAssistance = watch('assistance');
  const watchLeadership = watch('leadership');
  const watchNextCohort = watch('nextCohort');

  
  const surveys =  useSelector(getSurveys);

  useEffect(() => {
    dispatch(fetchCurrentSurveys());
    
  }, [dispatch]);

  const client = surveys.filter((client: { id: string; }) => client.id === (clientId|| props.clientId))[0];

// console.log(client, JSON.stringify(surveys))
const { user } = useAuth0();
const internalUser = useSelector(getInternalUser);

const submitForm = async(data: any) => {
  
  try{
    setLoading(true);
    setTimeout(()=> {
    
    
      // alert(JSON.stringify(data))
    data.email = user.nickname;
    data.match = props.currentSession.description;
    data.name = internalUser?.name;
    const feedback = {
      type: "final",
      clientId: props.currentSession.clientId,
      surveyId: props.currentSession.feedback_form,
      sessionId: props.currentSession.id,
      email: user.email,
      formResponse: data
    }
    // dispatch(sendSurveysResponse(feedback))
    const statusUpdate = props.currentSession.status === 'CONFIRMED'? 'FEEDBACK': 'COMPLETED';
    const sessionUpdate = {sessionId : props.currentSession.id, sessionStatus : statusUpdate, completementee: true }

    dispatch(sendFinalSurveysResponse({...feedback, ...sessionUpdate}));
    // dispatch(updateSession({id : props.currentSession.id, status : statusUpdate, completementee: true }));
    setLoading(false);
    setSuccess(true);
    // props.handleClose();
    }, 3000);
    
    setTimeout(() => {props.handleClose();}, 5000);
    
  } catch(e){
    alert(e);
  }
}
  return (
    <form style={{ alignItems:'center'}} onSubmit={handleSubmit(submitForm)} >
     
      <Grid container
            spacing={0}
            direction="column"
            // alignItems="center"
            // justifyContent="center"
            // style={{ minHeight: '100vh' }}
            >
              
        

        <Grid item xs={6} >
          <FormControl >
              <FormLabel className={classes.formLabel} >What skills/areas of improvement have your strengthened throughout your mentorship? *</FormLabel>
                <FormGroup  style={{ marginTop: '25px'}}>        
                {[
                  'Increased productivity',
                  'Identifying strengths',
                  'Increased confidence',
                  'Advancing in my career',
                  'Learning to beat imposter syndrome',
                  'Learning to negotiate for what I want',
                  'Balancing work and life',
                  'Building my network & influence',
                  'Other'].map((improvement, index) => 
                      <Grid  item xs={12} key={index}>
                        <FormLabel key={improvement} className={classes.checkbox}>
                          <input {...register('improvement', { required: true })} type='checkbox' value={improvement} name='improvement' style={{marginRight: '15px'}}/>
                          <span style={{color: 'black'}}>{improvement}</span>
                        </FormLabel>
                      </Grid>
                  )}
                </FormGroup>
                {(errors.improvement as any)?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
              </FormControl>
              { watchImprovement && watchImprovement.includes('Other') &&
                <Grid item xs={12}>
                  <br/>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherImprovement" 
                    control={control}
                  />
                </Grid>
              }
        </Grid> 
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>How has the opportunity for outside mentorship helped you? *</FormLabel>
            
                <FormGroup >
                  {['I feel more supported at work',
                    'I have grown personally',
                    'I have grown professionally',
                    'Promotion',
                    'Role change',
                    'Other'].map((opportunity, index) => (
                    // <FormControlLabel key={index} value={opportunity} control={<Radio color='primary' />} label={opportunity} />
                    <Grid  item xs={12} key={index}>
                        <FormLabel key={opportunity} className={classes.checkbox}>
                          <input {...register('opportunity', { required: true })} type='checkbox' value={opportunity} name='opportunity' style={{marginRight: '15px'}}/>
                          <span style={{color: 'black'}}>{opportunity}</span>
                        </FormLabel>
                      </Grid>
                    
                  ))}
                </FormGroup>
             
            {errors?.opportunity?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
          { watchOpportunity && watchOpportunity.includes('Other') &&
                <Grid item xs={12}>
                  <br/>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherOpportunity"
                    control={control}
                  />
                </Grid>
              }
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>In what ways have things improved since the mentorship? *</FormLabel>
            
                <FormGroup>
                  {['Makes me want to remain at my company',
                    'Attraction of talent',
                    'Better communications with teams',
                    'D&I initiatives have developed',
                    'Connecting well with leadership',
                    'Workplace culture',
                    'Other'].map((thingsImproved, index) => (
                      <Grid  item xs={12} key={index}>
                      <FormLabel key={thingsImproved} className={classes.checkbox}>
                        <input {...register('thingsImproved', { required: true })} type='checkbox' value={thingsImproved} name='thingsImproved' style={{marginRight: '15px'}}/>
                        <span style={{color: 'black'}}>{thingsImproved}</span>
                      </FormLabel>
                    </Grid>
                  ))}
                </FormGroup>
              
            {errors?.improved?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
          { watchThingsImproved && watchThingsImproved.includes('Other') &&
                <Grid item xs={12}>
                  <br/>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherThingsImproved"
                    control={control}
                  />
                </Grid>
              }
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>If you are not already in a leadership role, would you prefer to step into leadership?   *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['Yes','No','Not yet', 'Other:'].map((leadership, index) => (
                    <FormControlLabel key={index} value={leadership} control={<Radio color='primary' />} label={leadership} />
                  ))}
                </RadioGroup>
              )}
              name='leadership'
              control={control}
              rules={ { required: true } }
            />
            {errors?.leadership?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
          { watchLeadership && watchLeadership.includes('Other') &&
                <Grid item xs={12}>
                  <br/>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherLeadership"
                    control={control}
                  />
                </Grid>
              }
        </Grid>

        

        <Grid item xs={12} >
          <FormControl >
              <FormLabel className={classes.formLabel} >In what areas can Locelle improve on? *</FormLabel>
                <FormGroup  style={{ marginTop: '25px'}}>        
                {['Mentor matching',
                  'Video platform',
                  'Portal',
                  'Providing resources',
                  'Scheduling',
                  'Roundtables',
                  'Other'].map((assistance, index) => 
                      <Grid  item xs={12} key={index}>
                        <FormLabel key={assistance} className={classes.checkbox}>
                          <input {...register('assistance', { required: true })} type='checkbox' value={assistance} name='assistance' style={{marginRight: '15px'}}/>
                          <span style={{color:'black'}}>{assistance}</span>
                        </FormLabel>
                      </Grid>
                  )}
                </FormGroup>
                {(errors.assistance as any)?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}} >*Required</FormHelperText>}
              </FormControl>
              { watchAssistance && watchAssistance.includes('Other') &&
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherAssistance"
                    control={control}
                  />
                </Grid>
              }
        </Grid>  
        
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>If you had a positive experience with your mentor, share a testimonial that we can share with them to celebrate and provide a token of appreciation for their commitment to helping women in the workplace.</FormLabel>
              <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Please write testimonial here *' required fullWidth variant='outlined'/>
                    }
                    name="otherTestimonial"
                    control={control}
                  />
            {/* {errors?.ethnic?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>} */}
          </FormControl>
          </Grid> 

          <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>How would you like to proceed in the next cohort? *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    'Continue, same mentor',
                    'Continue, new mentor',
                    'Take a break from program',
                    'Other'].map((nextCohort, index) => (
                    <FormControlLabel key={index} value={nextCohort} control={<Radio color='primary' />} label={nextCohort} />
                  ))}
                </RadioGroup>
              )}
              name='nextCohort' 
              control={control}
              rules={ { required: true } }
            />
            {errors?.nextCohort?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
          { watchNextCohort && watchNextCohort.includes('Other') &&
                <Grid item xs={12}>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                    }
                    name="otherNextCohort"
                    control={control}
                  />
                </Grid>
              }
          </Grid>
        

        

      </Grid>
      {success ? (<Fab size='small' style={{backgroundColor: '#91cd91', position: 'absolute', bottom: 15, right: '15px'}}><CheckIcon fontSize='small' /></Fab>): (<Button style={{float: 'right'}} type="submit" variant='contained' disabled={loading}>Done</Button>)}
      {loading && (
                  <CircularProgress 
                  size={24}
                  sx={{
                    color: 'green',
                    position: 'absolute',
                    bottom: '20px',
                    right: '50px'
                  }}/>
              )}
    </form>
  )
}

export default FinalFeedbackForm;