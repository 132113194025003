import Profile from "./pages/Profile/Profile";
import MeetingRoom from "./components/meeting/MeetingRoom";
import ResponsiveDrawer from "./pages/layout";
import WelcomePage from "./pages/home";
import Sessions from "./pages/Sessions";
import LearningCenter from "./pages/Learning/LearningCenter";
import Community from "./pages/community/Community";
import FAQCommon from "./pages/faq";
import NewCommonSessions from "./pages/Coaching/NewCommonSessions";
import TasksNew from './pages/Tasks/TasksNew';

export const routes = [
  {
    path: '/',
    exact: true,
    layout: ResponsiveDrawer,
    component: WelcomePage
  },
  {
    path: '/profile',
    exact: true,
    layout: ResponsiveDrawer,
    component: Profile
  },
  {
    path: '/meetings',
    exact: true,
    layout: ResponsiveDrawer,
    component: Sessions
  },
  {
    path: '/sessions',
    exact: true,
    layout: ResponsiveDrawer,
    component: NewCommonSessions
  },
  {
    path: '/learning',
    exact: true,
    layout: ResponsiveDrawer,
    component: LearningCenter
  },
  {
    path: '/tasks',
    exact: true,
    layout: ResponsiveDrawer,
    component: TasksNew
  },
  {
    path: '/community',
    exact: true,
    layout: ResponsiveDrawer,
    component: Community
  },
  {
    path: '/faq',
    exact: true,
    layout: ResponsiveDrawer,
    component: FAQCommon
  }
];
