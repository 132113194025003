import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LinearProgress, ThemeProvider, Grid, Paper } from '@material-ui/core';
import { theme, useStyles } from '../styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../../../assets/logo.png';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { fetchCurrentSurveys, getSurveys } from '../../../../redux/actions/surveys';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@mui/material';
import menteeFormFields from './menteeFormFields';
import menteeFormSections from './menteeFormSections';
import api from '../../../../services/api';

export interface IFormProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  component: React.FC<any>;
  hasAuthId: boolean;
  userId: string;
  userEmail: string;
  surveyId: string;
}

createStore(
  {
    formDetails: menteeFormFields,
    feedbackDetails: menteeFormFields
  },
  { name: '', middleWares: [] }
);

export default function NewMenteeSurvey() {
  const classes = useStyles();
  const { code } = useParams<{ code: string }>();
  const { userId } = useParams<{ userId: string }>();
  const { surveyId } = useParams<{ surveyId: string }>();
  const stepsCount = menteeFormSections.length - 1;
  const [step, setStep] = useState(1);
  const [urlParamsInvalidMessage, setUrlParamsInvalidMessage] = useState(null);
  const [hasAuthId, setHasAuthId] = useState(false);
  const [userEmail, setUserEmail] = useState(false);

  const dispatch = useDispatch();
  const surveys: any[] = useSelector(getSurveys);

  useEffect(() => {
    api
      .get(`/surveys/validate/${code}/${userId}/${surveyId}`, {})
      .then(({ data }) => {
        console.log('URL params are valid', data);
        setHasAuthId(data.hasAuthId);
        setUserEmail(data.email);
      })
      .catch((error) => {
        const message = error?.response?.data?.message;
        console.log('Error validating URL params', message);
        setUrlParamsInvalidMessage('Error validating URL params: ' + message);
      });
  }, []);

  useEffect(() => {
    dispatch(fetchCurrentSurveys());
  }, [dispatch]);

  const surveyByClient = surveys.find(
    (client: { code: string; surveyId: string }) => client.code === code && !!client.surveyId
  );

  console.log('SurveysBy Client', surveyByClient);

  return (
    <StateMachineProvider>
      {!surveyByClient ? (
        <div style={{ marginLeft: '50%', marginTop: '30%' }}>
          <Typography>Loading</Typography>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.pageContent}>
            {urlParamsInvalidMessage && (
              <Typography variant="h6" align="center" component="h2" sx={{ mb: 3 }}>
                {urlParamsInvalidMessage}
              </Typography>
            )}
            <Paper>
              {!urlParamsInvalidMessage && (
                <Grid
                  className={classes.formContainer}
                  container
                  spacing={5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid style={{ paddingTop: 30 }} item>
                    <img src={Logo} alt="logo" className={classes.logo} />
                  </Grid>

                  {step < stepsCount && (
                    <Grid className={classes.headerBlock} item xs={12}>
                      <Typography variant="h4" align="center" component="h1" gutterBottom>
                        Onboarding Mentee
                      </Typography>
                      <Typography variant="h6" align="center" component="h2" sx={{ mb: 3 }}>
                        {surveyByClient.name}
                      </Typography>

                      {step === 1 && (
                        <Box>
                          <Typography paragraph align="center" sx={{ mt: 2 }}>
                            Approximate completion time: 2 minutes
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid container spacing={5} alignItems="center" justifyContent="center">
                      {menteeFormSections.map(
                        (menteeFormSection: IFormProps, index: number) =>
                          menteeFormSection.step === step && (
                            <menteeFormSection.component
                              key={index}
                              step={step}
                              setStep={setStep}
                              client={surveyByClient}
                              hasAuthId={hasAuthId}
                              userId={userId}
                              userEmail={userEmail}
                              surveyId={surveyId}
                            />
                          )
                      )}
                      {step <= stepsCount && (
                        <Grid item xs={12}>
                          <Typography component="h6" align="right">
                            Page {step} of {stepsCount}
                            <LinearProgress variant="determinate" color="secondary" value={(step / stepsCount) * 100} />
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </div>
        </ThemeProvider>
      )}
    </StateMachineProvider>
  );
}
