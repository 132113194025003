import React, { useState } from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Grid,
  Hidden
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { IFormProps } from './section.interface';

const Section2: React.FC<IFormProps> = ({ step, setStep }) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timezone, setTimeZone] = useState(currentTimezone);
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentee
  });
  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { mentee: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentee: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your age? (we are collecting this data to best tailor the program) *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['18-29', '30-39', '40-49', '50+', 'Prefer not to say'].map((age, index) => (
                    <FormControlLabel key={index} value={age} control={<Radio color="primary" />} label={age} />
                  ))}
                </RadioGroup>
              )}
              name="age"
              control={control}
              rules={{ required: true }}
            />
            {errors?.age?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Where are you located? Please add City and Country *</FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your answer" fullWidth variant="outlined" />}
              name="location"
              control={control}
              rules={{ required: true }}
            />
            {errors?.location?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Hidden>
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }}>
              <FormLabel className={classes.formLabel}>Timezone you are in? *</FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Your answer"
                    fullWidth
                    variant="outlined"
                    value={timezone}
                    onChange={(e) => setTimeZone(e.target.value)}
                  />
                )}
                name="timezone"
                control={control}
                // rules={ { required: true, } }
              />
              {errors?.timezone?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Hidden>
        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section2;
