import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import api from '../../services/api';
import { Box, Button, Fab, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CachedIcon from '@mui/icons-material/Cached';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NOTE_EDITOR_API_KEY } from '../../constants/constants';
import { notesEditorConfig } from './MeetingRoomDialog.constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import { noop } from '../../utils/basic.utils';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';
import {NoteTypes, SessionNotesTemplates, STAGE_MAPPING} from '../../enums/enums';

interface NoteEditorProps {
  noteType: NoteTypes;
  sessionId: string;
  stage: string;
  onClose: () => void;
  closeBtnOnLeft?: boolean;
}

interface NoteTemplatesResponse {
  id: string;
  value: string;
}

const NoteEditor = (props: NoteEditorProps) => {
  const closeBtnOnLeft = props.closeBtnOnLeft;
  const stage = props.stage;
  const noteType = props.noteType;
  const sessionId = props.sessionId;
  const debounceDelayMs = 2000;
  const [notesTemplate, setNotesTemplate] = useState<string>(null);
  const [value, setValue] = useState(null);
  const [notesSaving, setNotesSaving] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [notesFetched, setNotesFetched] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const editorRef = useRef(null);
  const path = NoteTypes.Mentoring === noteType ? '/sessions' : '/coaching';

  useEffect(() => {
    api.get<NoteTemplatesResponse>(`/config/${STAGE_MAPPING[stage]}`).then(({ data }) => {
      setNotesTemplate(data.value);
      fetchNotes(data.value);
    });
  }, []);

  const save = () => {
    if (editorRef.current) {
      saveNotes(editorRef.current.getContent({ format: 'raw' }));
    }
  };

  const resetNotes = () => {
    setDialogOpen(false);
    setValue(notesTemplate);
  };

  const fetchNotes = (noteInitialValue: string): void => {
    api.get<string>(`${path}/notes/${sessionId}`).then(({ data }) => {
      const sessionNotesSavedValue = data;
      setValue(sessionNotesSavedValue || noteInitialValue);
      setNotesFetched(true);
    });
  };

  const saveNotes = (content: any): void => {
    const payload = { id: sessionId, notes: content };
    setNotesSaving(true);

    api
      .patch(`${path}/notes/`, payload)
      .then(() => noop)
      .catch((e) => console.error('Error Saving Notes: ', e))
      .finally(() => setTimeout(() => setNotesSaving(false), 1000));
  };

  const delayedSave = useCallback(debounce(saveNotes, debounceDelayMs), []);

  return (
    <>
      <Box sx={{ height: '100%', width: closeBtnOnLeft ? 'unset' : '850px' }}>
        <Box display="flex" alignItems="center" justifyItems="space-between">
          {closeBtnOnLeft && (
            <Box>
              <Tooltip title="Hide Notes" arrow placement="top">
                <Fab variant="extended" size="small" onClick={props.onClose} color="secondary" sx={{ ml: 1 }}>
                  <KeyboardDoubleArrowRightIcon />
                </Fab>
              </Tooltip>
            </Box>
          )}
          <Box flexGrow={1}>
            <Typography variant="h6" sx={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
              {NoteTypes.Mentoring === noteType ? `${stage} Notes` : 'Coaching Session Notes'}
            </Typography>
            <Tooltip title="Reset Notes" arrow placement="top">
              <span>
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<CachedIcon />}
                  size="small"
                  sx={{ ml: 2, mr: 2, py: '1px' }}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  disabled={!notesFetched || notesSaving}
                >
                  Reset
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Save Changes" arrow placement="top">
              <span>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{ py: '1px' }}
                  endIcon={<SaveAltIcon />}
                  size="small"
                  onClick={save}
                  disabled={!notesFetched || notesSaving}
                >
                  {notesSaving ? 'Saving..' : ' Save'}
                </Button>
              </span>
            </Tooltip>
          </Box>
          {!closeBtnOnLeft && (
            <Box>
              <Tooltip title="Close Notes" arrow placement="top">
                <IconButton onClick={props.onClose} color="primary">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        {!notesFetched && (
          <Box
            sx={{
              width: '100%',
              height: '95%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div>
              <CircularProgress
                color="secondary"
                style={{
                  verticalAlign: 'middle'
                }}
              />
              <Typography sx={{ display: 'inline-block', pl: '1rem', verticalAlign: 'sub' }}>
                Loading notes..
              </Typography>
            </div>
          </Box>
        )}

        {notesFetched && (
          <Editor
            apiKey={NOTE_EDITOR_API_KEY}
            value={value}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            onEditorChange={(newValue, editor) => {
              if (!isInitialLoad) {
                delayedSave(editor.getContent({ format: 'raw' }));
                setValue(newValue);
              }
              setIsInitialLoad(false);
            }}
            init={notesEditorConfig}
          />
        )}
      </Box>
      {dialogOpen && (
        <ConfirmationDialog
          isOpen={dialogOpen}
          title="Reset Notes?"
          message="Are you sure you want to reset your notes? All changes will be lost."
          onConfirmBtn={resetNotes}
          onCancelBtn={() => setDialogOpen(false)}
        />
      )}
    </>
  );
};

export default NoteEditor;
