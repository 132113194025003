import { Button, Card, CardContent, CardHeader, CircularProgress, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Session } from '../../../models/session';
import { sendSurveysResponse } from '../../../redux/actions/surveysResponse';
import { updateSession } from '../../../redux/actions/sessions';
import CheckIcon from '@mui/icons-material/Check';
import Logo from '../../../assets/logo.png';

type Props = {
  clientId: string;
  currentSession: Session;
  handleClose: () => void;
}

const useStyles = makeStyles({
  formLabel: {
    marginTop: '25px',
    marginBottom: '15px',
    fontWeight: 'bold',
    color: '#4c2525',
  },
  checkbox: { color: '#000000', fontSize: '18px', fontStyle: 'normal', fontWeight: 300 },
  errorText: {
    color: 'red'
  }
});

function FinalFeedbackFormMentor(props: Props) {

  const { mentorObject } = useParams<{ clientId: string, mentorObject: string }>();
  const mentorData = JSON.parse(atob(mentorObject));

  const [client, setClient] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, getValues, watch, control, formState: { errors } } = useForm();


  const watchAvailability = watch('availability');

  const submitForm = async (data: any) => {
    const { mentorEmail, surveyid, clientId, name, description, id, status } = mentorData;
    try {
      setLoading(true);
      setTimeout(() => {
        data.email = mentorEmail;
        data.match = description;
        data.name = name;
        const feedback = {
          type: "final",
          clientId: clientId,
          surveyId: surveyid,
          email: mentorEmail,
          formResponse: data
        }
        // dispatch(sendSurveysResponse(feedback));
        const statusUpdate = status === 'CONFIRMED'? 'FEEDBACK': 'COMPLETED';

        dispatch(sendSurveysResponse(feedback))
        dispatch(updateSession({ id: id, status: statusUpdate, completementor: true }));
        // dispatch(updateSession({ id: id, status: statusUpdate, completementor: true }));
        setLoading(false);
        setSuccess(true);

      }, 3000);

    } catch (e) {
      alert(e);
    }
  }
  return (
    <div style={{ backgroundColor: '#f0ebf8' }}>
      {success ? (
        <Card elevation={0}>
          <CardContent>
            <img src={Logo} style={{ display: 'block', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '100px' }} />
            <Typography align='center'>Thank you for submiting! You can now close this window</Typography>
          </CardContent>

        </Card>) : (<>
          <Card style={{ margin: '0 auto', width: '700px', borderTop: '10px solid #673ab7', borderRadius: '10px' }} elevation={2} >
            <img src={Logo} style={{ display: 'block', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '100px' }} />
            <Typography variant="h6" align='center'>{`${mentorData?.company} Final Session -  ${mentorData?.name}`}</Typography>
            <CardHeader
              subheader="Thank you for connecting with Locelle for all your sessions! We want to make sure this year is successful for everyone, please help us in helping you."
            />
          </Card>
          <br />
          <Card style={{ margin: '0 auto', width: '700px', borderRadius: '15px 15px 0 0' }} elevation={2} >
            <CardContent>
              {client?.name}
              <form style={{ alignItems: 'center' }} onSubmit={handleSubmit(submitForm)} >

                <Grid container
                  spacing={0}
                  direction="column"
                // alignItems="center"
                // justifyContent="center"
                // style={{ minHeight: '100vh' }}
                >

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>Have you grown personally and professionally as a result of mentorship program? *</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            {['Yes',
                              'No'].map((grown, index) => (
                                <FormControlLabel key={index} value={grown} control={<Radio color='primary' />} label={grown} />
                              ))}
                          </RadioGroup>
                        )}
                        name='grown'
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors?.grown?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>Do you have any feedback or comments for your mentee? This can be kept confidential*</FormLabel>
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined' />
                        }
                        name="menteeFeedback"
                        control={control}
                      />
                      {errors?.menteeFeedback?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>What is the one thing that would make you happier about the program? Things we can improve upon.*</FormLabel>
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined' />
                        }
                        name="locelleImprovement"
                        control={control}
                      />
                      {errors?.locelleImprovement?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>What is the one thing we are doing well?*</FormLabel>
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined' />
                        }
                        name="locelleWell"
                        control={control}
                      />
                      {errors?.locelleWell?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>What else can Locelle do to support you?*</FormLabel>
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined' />
                        }
                        name="support"
                        control={control}
                      />
                      {errors?.support?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>What is your availability moving forward? 1 hour per month? *</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            {[
                              'Can commit to 1 year',
                              'Can commit to 6 months',
                              'Can commit to 3 months',
                              'Pause mentorship for now',
                              'Other'].map((availability, index) => (
                                <FormControlLabel key={index} value={availability} control={<Radio color='primary' />} label={availability} />
                              ))}
                          </RadioGroup>
                        )}
                        name='availability'
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors?.availability?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                  </Grid>
                  {watchAvailability && watchAvailability.includes('Other') &&
                    <Grid item xs={12}>
                      <br />
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Other: *' required fullWidth variant='outlined' />
                        }
                        name="otherAvailability"
                        control={control}
                      />
                    </Grid>
                  }

                </Grid>
                {success ? (<Fab size='small' style={{ backgroundColor: '#91cd91', position: 'absolute', bottom: 15, right: '15px' }}><CheckIcon fontSize='small' /></Fab>) : (<Button style={{ float: 'right', bottom: '5px', backgroundColor: '#673ab7' }} type="submit" variant='contained' disabled={loading}>Done</Button>)}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'green',
                      position: 'absolute',
                      bottom: '20px',
                      right: '50px'
                    }} />
                )}
              </form>
            </CardContent>


          </Card>
        </>)}
    </div>
  )
}

export default FinalFeedbackFormMentor;
