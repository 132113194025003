import { AnyAction } from 'redux';
import { ContentActionTypes } from '../actions/content';

const initialState = {
  files: [],
  learningTools: [],

};

export function ContentReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case ContentActionTypes.FETCH:
      return {
        ...state,
        files: action.payload
      };

    case ContentActionTypes.FETCH_LEARNING_TOOLS:
      return {
        ...state,
        learningTools: action.payload
      };

    default:
      return state;
  }
}
