import React, { useEffect, useState } from 'react';
import ProgressComponent from '@material-ui/core/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, Grid, makeStyles, MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { findSessionsByAuthId, getUserSessions, updateSession } from '../../redux/actions/sessions';
import { format, parseISO } from 'date-fns';
import MeetingRoomFullScreenDialog from './MeetingRoomFullScreenDialog';
import CancelSessionDialog from './CancelSessionDialog';
import { CgShapeCircle } from 'react-icons/all';
import { Session } from '../../models/session';
import { Box, Button, Card, Chip, DialogContent, Link, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { getUserProfile } from '../../redux/actions/user';
import MeetAndGreetFeedback from './feedback/MeetAndGreetFeedback';
import FinalSessionFeedback from './feedback/FinalSessionFeedback';
import Logo from '../../assets/logo.png';
import NoteEditor from './NoteEditor';
import Dialog from '@mui/material/Dialog';
import { NoteTypes, SessionStageType, SessionStatusType, UserType } from '../../enums/enums';

const useStyles = makeStyles({
  session: {
    padding: '10px',
    marginTop: '10px',
    borderLeft: '5px solid #342e40',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      backgroundColor: '#fbfbfb'
      //  transform: "scale3d(1.01, 1.01, 1)"
    }
  },
  markComplete: {
    fontSize: '12px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#5dab5d',
      color: 'white'
      //  transform: "scale3d(1.01, 1.01, 1)"
    }
  }
});

interface ILocelleUser {
  id: string;
  type: UserType;
}

enum SessionsTabType {
  Current,
  Completed
}

function Sessions() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showMeeting] = useState(false);
  const [value, setValue] = React.useState<SessionsTabType>(SessionsTabType.Current);

  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [openFeedbackForm, setOpenFeedbackForm] = React.useState(false);
  const [openNoteEditor, setOpenNoteEditor] = React.useState(false);
  const [currentSession, setCurrentSession] = React.useState(null);
  const [openFinalFeedbackForm, setOpenFinalFeedbackForm] = React.useState(false);
  const internalUser = useSelector(getUserProfile);
  const [locelleUser, setLocelleUser] = useState<ILocelleUser>(internalUser);

  const [editSession, setEditSession] = useState(null);

  const { user } = useAuth0();

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const classes = useStyles();
  const sessions = useSelector(getUserSessions);
  const currentUser = localStorage.getItem('LOCELLE:USER_TYPE');
  const isMentor = UserType.Mentor === currentUser;
  const isMentee = UserType.Mentee === currentUser;

  useEffect(() => {
    dispatch(findSessionsByAuthId(user.sub));
    setLoading(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: SessionsTabType) => {
    setValue(newValue);
  };

  const handleClose = (): void => {
    setOpenCancelDialog(false);
    setOpenNoteEditor(false);
    setOpenFeedbackForm(false);
    setOpenFinalFeedbackForm(false);
    refreshSessionList();
    setCurrentSession(null);
  };

  const refreshSessionList = () => {
    dispatch(findSessionsByAuthId(user.sub));
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#040404'
      }
    }
  });

  const sessionsFilter = (status: string[]) => {
    return sessions
      .filter((session: Session) => status.includes(session.status))
      .map((session: Session) => (
        <Box key={session.id}>
          <Card elevation={6} className={classes.session}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography>
                  <Chip
                    sx={
                      SessionStatusType.Confirmed === session.status
                        ? { backgroundColor: '#b6dfb6', color: 'green' }
                        : {}
                    }
                    icon={
                      <CgShapeCircle style={SessionStatusType.Confirmed === session.status ? { color: 'green' } : {}} />
                    }
                    label={session.status}
                  />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{format(parseISO(session.date), 'MM/dd/yyyy - HH:mm')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{session.stage}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Tooltip
                  title="View LinkedIn Profile"
                  arrow
                  placement="top"
                  disableHoverListener={(isMentor && !session.menteelinkedin) || (isMentee && !session.mentorlinkedin)}
                >
                  <Link
                    href={isMentor ? session.menteelinkedin : session.mentorlinkedin}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                  >
                    <Typography sx={{ display: 'flex', justifyContent: 'start' }}>
                      <LinkedInIcon
                        sx={{ mr: 1 }}
                        color={
                          (isMentor && !session.menteelinkedin) || (isMentee && !session.mentorlinkedin)
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                      {isMentor ? session?.menteename : session?.mentorname}
                    </Typography>
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {SessionStatusType.Confirmed === session.status && parseInt(session.diff) <= 0 && (
                    <MeetingRoomFullScreenDialog
                      openNoteEditor={openNoteEditor}
                      mentor={{ id: session.mentorid, name: session.mentorname, email: session.mentoremail }}
                      session={session}
                      accessViaPortal={true}
                      onClose={handleClose}
                    />
                  )}
                  {[SessionStatusType.Confirmed, SessionStatusType.Feedback].includes(session.status) && (
                    <Button
                      onClick={() => {
                        if (session.stage === SessionStageType.MeetAndGreet) {
                          setEditSession(session);
                          setOpenFeedbackForm(true);
                        } else if (session.stage === '6 Session' || SessionStageType.FinalSession === session.stage) {
                          setEditSession(session);
                          setOpenFinalFeedbackForm(true);
                        } else {
                          dispatch(updateSession({ id: session.id, status: SessionStatusType.Completed }));
                        }
                      }}
                      variant="outlined"
                      sx={{
                        mr: 1,
                        textTransform: 'none',
                        color: '#342E40',
                        borderColor: '#342E40',
                        '&:hover': {
                          backgroundColor: '#6cb26c',
                          color: 'white'
                        }
                      }}
                      className={classes.markComplete}
                      startIcon={<CheckIcon />}
                      disabled={
                        parseInt(session.diff) < 0 ||
                        (isMentor && session.completementor) ||
                        (isMentee && session.completementee)
                          ? true
                          : false
                      }
                    >
                      {(isMentor && session.completementor) || (isMentee && session.completementee)
                        ? 'Feedback sent'
                        : 'Mark Complete'}
                    </Button>
                  )}
                  {isMentee && (
                    <Button
                      onClick={() => {
                        setCurrentSession(session);
                        setOpenNoteEditor(true);
                      }}
                      variant="outlined"
                      color="secondary"
                      sx={{
                        mr: 1,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#593a77',
                          color: 'white'
                        }
                      }}
                      startIcon={<FormatListBulletedIcon />}
                    >
                      View Notes
                    </Button>
                  )}
                  {SessionStatusType.Completed !== session.status && (
                    <Button
                      color="primary"
                      sx={{
                        mr: 1
                      }}
                      onClick={() => {
                        setEditSession(session);
                        setOpenCancelDialog(true);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
      ));
  };

  return (
    <Box style={containerStyle}>
      {loading && <ProgressComponent />}

      {!showMeeting && !loading && (
        <>
          <Dialog maxWidth="md" open={openNoteEditor} onClose={handleClose}>
            <DialogContent sx={{ p: 1, pt: 0, height: '900px' }}>
              <NoteEditor
                sessionId={currentSession?.id}
                stage={currentSession?.stage}
                onClose={handleClose}
                noteType={NoteTypes.Mentoring}
              />
            </DialogContent>
          </Dialog>
          <MuiThemeProvider theme={theme}>
            <Typography sx={{ mb: 3, mt: 4, fontSize: '18px', color: 'grey', display: 'flex', alignContent: 'center' }}>
              Please mark all your sessions as completed once you have attended them
              <CheckIcon sx={{ ml: 1, color: 'green' }} />
            </Typography>
            <Typography sx={{ mb: '20px' }}>
              Total number of sessions scheduled:
              <span style={{ color: 'green', fontSize: '18px' }}>
                &nbsp;<b>{sessions.length}</b>
              </span>
            </Typography>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary">
              <Tab label="Current" value={SessionsTabType.Current} />
              <Tab label="Completed" value={SessionsTabType.Completed} />
            </Tabs>
          </MuiThemeProvider>

          <br />
          {sessions.length > 0 ? (
            <Grid container spacing={2} style={{ marginLeft: '10px' }}>
              <Grid item xs={1}>
                <Typography sx={{ fontWeight: 'bold' }}>Status</Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: 0 }}>
                <Typography sx={{ fontWeight: 'bold' }}> Date & Time</Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: 0 }}>
                <Typography sx={{ fontWeight: 'bold' }}>Session</Typography>
              </Grid>
              <Grid item xs={2} style={{ paddingLeft: 0 }}>
                <Typography sx={{ fontWeight: 'bold' }}>Your Match</Typography>
              </Grid>
              <Grid item xs={5} style={{ paddingLeft: 0 }}>
                <Typography sx={{ fontWeight: 'bold' }}>Actions</Typography>
              </Grid>
            </Grid>
          ) : sessions.length === 0 ? (
            <Typography variant="h6">Looks like you don't have sessions scheduled yet. We're working on it!</Typography>
          ) : (
            <ProgressComponent />
          )}
          {SessionsTabType.Current === value && (
            <>{sessionsFilter([SessionStatusType.Confirmed, SessionStatusType.Feedback])}</>
          )}
          {SessionsTabType.Completed === value && sessionsFilter([SessionStatusType.Completed])}
        </>
      )}
      <CancelSessionDialog
        isOpen={openCancelDialog}
        handleClose={handleClose}
        currentSession={editSession}
        refreshSessionList={refreshSessionList}
      />
      <MeetAndGreetFeedback
        isOpen={openFeedbackForm}
        handleClose={handleClose}
        currentSession={editSession}
        refreshSessionList={refreshSessionList}
      />
      <FinalSessionFeedback
        isOpen={openFinalFeedbackForm}
        handleClose={handleClose}
        currentSession={editSession}
        refreshSessionList={refreshSessionList}
        locelleUser={locelleUser}
      />
    </Box>
  );
}

export default Sessions;
