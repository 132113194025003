import React, { useEffect } from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Button,
  Grid
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, TextField } from '@mui/material';
import { IFormProps } from '../NewCoachSurvey';

const Section2: React.FC<IFormProps> = ({ step, setStep }) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.newCoach
  });
  const classes = useStyles();
  const watchIndustriesExperience = watch('industriesExperience');
  const watchRoleExperience = watch('roleExperience');
  const watchManagementExperience = watch('managementExperience');
  const watchLeadershipExperience = watch('leadershipExperience');
  const watchExperienceExtent = watch('experienceExtent');
  const watchCoachingStyle = watch('coachingStyle');
  const watchTraining = watch('training');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { newCoach: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { newCoach: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following industries do you have experience working in outside of coaching? *
            </FormLabel>
            <FormGroup>
              {[
                'Financial Services',
                'Technology (Software/IT/Hardware/IoT)',
                'Retail',
                'Manufacturing',
                'Construction',
                'Healthcare',
                'Consulting',
                'Military',
                'Media & Entertainment',
                'Government',
                'Small Business/ Startups',
                'Education',
                'Professional Services (HR, Legal, Accounting)',
                'Other'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('industriesExperience', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="industriesExperience"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.industriesExperience?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.industriesExperience?.message}</FormHelperText>
          </FormControl>
          {watchIndustriesExperience && watchIndustriesExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherIndustriesExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following roles or functions do you have experience in? *
            </FormLabel>
            <FormGroup>
              {[
                'Accounting/Finance',
                'Administration and Operations',
                'Customer Service/Client Success',
                'Engineering',
                'Software Development/IT',
                'Human Resources/ People & Culture',
                'Legal',
                'Digital Marketing',
                'Product Management',
                'Research and Development',
                'Sales/Business Development',
                'Community',
                'Other'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('roleExperience', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="roleExperience"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.roleExperience?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.roleExperience?.message}</FormHelperText>
          </FormControl>
          {watchRoleExperience && watchRoleExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherRoleExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How many years of people management experience do you have? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['10+ years', '5+ years', '1-4 years', 'No experience', 'Other'].map(
                    (managementExperience, index) => (
                      <FormControlLabel
                        key={index}
                        value={managementExperience}
                        control={<Radio color="primary" />}
                        label={managementExperience}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="managementExperience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.managementExperience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchManagementExperience && watchManagementExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherManagementExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which level of leadership experience have you achieved in your career? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'C-Suite/Founder',
                    'VP/EVP',
                    'Director/Sr. Manager (People Management)',
                    'Manager (Projects)',
                    'Individual Contributor',
                    'Other'
                  ].map((leadershipExperience, index) => (
                    <FormControlLabel
                      key={index}
                      value={leadershipExperience}
                      control={<Radio color="primary" />}
                      label={leadershipExperience}
                    />
                  ))}
                </RadioGroup>
              )}
              name="leadershipExperience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.leadershipExperience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchLeadershipExperience && watchLeadershipExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherLeadershipExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              What coaching credentials do you hold (e.g. ACC, ICF Certified) or indicate no coaching credentials at
              this time: *{' '}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  sx={{ ml: 2 }}
                  multiline
                  rows={4}
                  placeholder="Your answer"
                  fullWidth
                  variant="outlined"
                  {...field}
                />
              )}
              name="credentials"
              control={control}
              rules={{ required: true }}
            />
            {errors?.credentials?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Estimated number of coaching hours completed? *</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  sx={{ ml: 2 }}
                  multiline
                  rows={2}
                  placeholder="Your answer"
                  fullWidth
                  variant="outlined"
                  {...field}
                />
              )}
              name="numberOfCoachingHours"
              control={control}
              rules={{ required: true }}
            />
            {errors?.numberOfCoachingHours?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Indicate the extent of your experience coaching individuals at various levels of their careers: *
            </FormLabel>
            <FormGroup>
              {[
                'C-Suite/Founder',
                'VP/EVP',
                'Director/Sr. Manager (People Management)',
                'Manager (Projects)',
                'Individual Contributor',
                'Other'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('experienceExtent', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="experienceExtent"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.experienceExtent?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.experienceExtent?.message}</FormHelperText>
          </FormControl>
          {watchExperienceExtent && watchExperienceExtent?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherExperienceExtent"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>Which best describes your coaching style? </FormLabel>
            <FormGroup>
              {['Very direct', 'Somewhat direct', 'Flexible', 'Systemic', 'Accountability', 'Self-driven', 'Other'].map(
                (optionValue, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel key={optionValue} className={classes.checkbox}>
                      <input
                        {...register('coachingStyle', { required: true })}
                        type="checkbox"
                        value={optionValue}
                        name="coachingStyle"
                        style={{ margin: '15px', transform: 'scale(1.2)' }}
                      />
                      <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                    </FormLabel>
                  </Grid>
                )
              )}
            </FormGroup>
            {errors.coachingStyle?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.coachingStyle?.message}</FormHelperText>
          </FormControl>
          {watchCoachingStyle && watchCoachingStyle?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherCoachingStyle"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>Are you comfortable with group coaching? * </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['Yes (training will be provided)', 'No - only 1:1 coaching'].map((optionValue, index) => (
                    <FormControlLabel
                      key={index}
                      value={optionValue}
                      control={<Radio color="primary" />}
                      label={optionValue}
                    />
                  ))}
                </RadioGroup>
              )}
              name="groupCoaching"
              control={control}
              rules={{ required: true }}
            />
            {errors?.groupCoaching?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              What is your time commitment? Min. commitment is 6 months *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['1 year', '6 months', 'Multi-year'].map((optionValue, index) => (
                    <FormControlLabel
                      key={index}
                      value={optionValue}
                      control={<Radio color="primary" />}
                      label={optionValue}
                    />
                  ))}
                </RadioGroup>
              )}
              name="timeCommitment"
              control={control}
              rules={{ required: true }}
            />
            {errors?.timeCommitment?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Can you commit to additional coaching training (as part of onboarding) - unpaid *{' '}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['Yes', 'No', 'Other'].map((optionValue, index) => (
                    <FormControlLabel
                      key={index}
                      value={optionValue}
                      control={<Radio color="primary" />}
                      label={optionValue}
                    />
                  ))}
                </RadioGroup>
              )}
              name="training"
              control={control}
              rules={{ required: true }}
            />
            {errors?.training?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchTraining && watchTraining?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherTraining"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section2;
