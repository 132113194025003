import React from 'react';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, TextField } from '@mui/material';
import { IFormProps } from '../NewMentorSurvey';

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.newMentor
  });
  const classes = useStyles();
  const watchEthnic = watch('ethnic');
  const watchPronoun = watch('pronoun');
  const watchLeadershipExperience = watch('leadershipExperience');
  const watchAreaOfExpertise = watch('areaOfExpertise');

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 1: ', data);
    actions.updateAction({ formDetails: { newMentor: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Our community is diverse and we are proud of it. We appreciate it if you can share your ethnic origin. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'European',
                    'Hispanic/Latinx',
                    'South American',
                    'African',
                    'Indigenous',
                    'White/Caucasian',
                    'Arab',
                    'Asian/South Asian',
                    'Prefer not to answer',
                    'Other'
                  ].map((ethnic, index) => (
                    <FormControlLabel key={index} value={ethnic} control={<Radio color="primary" />} label={ethnic} />
                  ))}
                </RadioGroup>
              )}
              name="ethnic"
              control={control}
              rules={{ required: true }}
            />
            {errors?.ethnic?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchEthnic && watchEthnic?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherEthnic"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              We are intentional about building a diverse community. We appreciate if you would be willing to share how
              you identify yourself. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['She/Her', 'He/Him', 'They/Them', 'Ze/Hir', 'Xe/Xem', 'Prefer not to answer', 'Other'].map(
                    (pronoun, index) => (
                      <FormControlLabel
                        key={index}
                        value={pronoun}
                        control={<Radio color="primary" />}
                        label={pronoun}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="pronoun"
              control={control}
              rules={{ required: true }}
            />
            {errors?.pronoun?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchPronoun && watchPronoun?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      placeholder="Your answer *"
                      required
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherPronoun"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>I have experience as a(n): *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['Entrepreneur', 'Employee at a company (progressive roles)', 'Both, Entrepreneur and Employee'].map((pronoun, index) => (
                    <FormControlLabel key={index} value={pronoun} control={<Radio color="primary" />} label={pronoun} />
                  ))}
                </RadioGroup>
              )}
              name="experienceAs"
              control={control}
              rules={{ required: true }}
            />
            {errors?.experienceAs?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>How many years of experience do you have as a mentor: *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['No experience', '1-3 years', '3-5 years', '5-10 years', '10+ years'].map((pronoun, index) => (
                    <FormControlLabel key={index} value={pronoun} control={<Radio color="primary" />} label={pronoun} />
                  ))}
                </RadioGroup>
              )}
              name="mentoringExperience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.mentoringExperience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which level of leadership experience have you achieved in your career? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'C-Suite/Founder',
                    'VP/EVP',
                    'Director/Sr. Manager (People Management)',
                    'Manager (Projects)',
                    'Individual Contributor',
                    'Other'
                  ].map((leadershipExperience, index) => (
                    <FormControlLabel
                      key={index}
                      value={leadershipExperience}
                      control={<Radio color="primary" />}
                      label={leadershipExperience}
                    />
                  ))}
                </RadioGroup>
              )}
              name="leadershipExperience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.leadershipExperience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchLeadershipExperience && watchLeadershipExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherLeadershipExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What do you enjoy most about being a mentor: *</FormLabel>
            <FormGroup>
              {[
                'Growing my network',
                'Developing or enhancing leadership skills',
                'Sharing information/give back',
                'Gaining self fulfillment and personal growth',
                'Gaining new perspectives from others',
                'Inspiring and influencing new generations of entrepreneurs',
                'Helping people advance their careers'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('enjoyAsMentor', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="enjoyAsMentor"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.enjoyAsMentor?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.enjoyAsMentor?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following topics best describes your area of expertise: *
            </FormLabel>
            <FormGroup>
              {[
                'Negotiation',
                'Conflict resolution',
                `Building confidence/ navigating imposter syndrome`,
                'Public speaking',
                'Stepping into a leadership role',
                'Balancing work and life',
                'Building a meaningful network',
                'Building influence at work',
                'DEIB advice (diversity, equity, inclusion, belonging)',
                'Situational leadership',
                'Supporting new managers',
                'Accelerating career growth',
                'Business Strategy',
                'Other'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('areaOfExpertise', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="areaOfExpertise"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.areaOfExpertise?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.areaOfExpertise?.message}</FormHelperText>
          </FormControl>
          {watchAreaOfExpertise && watchAreaOfExpertise?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherAreaOfExpertise"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your personality style? (Select all that apply): *
            </FormLabel>
            <FormGroup>
              {[
                'Motivational',
                'Upbeat',
                'Calm',
                'Direct',
                'Realist',
                'Compassionate',
                'Extroverted',
                'Introverted'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('personality', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="personality"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.personality?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.personality?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;
