import { Button, Container } from '@mui/material';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import api from '../../services/api';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/logo.png';

type Props = {};

const Community = (props: Props) => {
  const [buttonState, setButtonState] = useState(false);
  const { user } = useAuth0();

  function sendRequest() {
    api
      .post('/users/slack', user)
      .then(() => toast.success('Request submitted. Thank you!'))
      .catch((error) => {
        toast.error('Request failed, please try again later');
        throw error;
      });
    setButtonState(true);
  }

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '86vh',
          paddingTop: '70px',
          paddingLeft: '33%',
          paddingRight: '30%'
        }}
      >
        <Typography
          style={{
            color: '#342E40',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '35px'
          }}
        >
          Join our Community on Slack
        </Typography>
        <Typography>Here is how to use Slack Community:</Typography>
        <li>Engage with other participants in the program</li>
        <li>Connect with team Locelle for any technical issues or questions</li>
        <li>Engage on roundtable topics or participate in discussions</li>

        <Typography sx={{ width: { sm: '250px', md: '445px' } }}>
          <Button
            variant="contained"
            style={{
              color: 'white',
              background: '#342E40',
              marginTop: '15px',
              textTransform: 'none',
              width: '100%'
            }}
            onClick={sendRequest}
            disabled={buttonState}
          >
            {!buttonState ? 'Send Request To Join the Community' : 'Request Sent'}
          </Button>
        </Typography>
        <br />
        <Typography
          sx={{
            width: { sm: '250px', md: '445px' },
            height: { sm: '150px', md: '315px' }
          }}
        >
          <iframe
            style={{ border: '0', borderRadius: '5px' }}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/x_y_9BxR7Dk"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Typography>
      </div>
    </div>
  );
};

export default Community;
