export default {
  all: {
    clientId: '',
    clientName: '',
    email: '',
    alternateEmail: '',
    firstName: '',
    lastName: '',
    city: '',
    country: '',
    timezone: '',
    age: '',
    title: '',
    experience: '',
    company: '',
    linkedin: '',
    learningStyle: '',
    careerGoals: '',
    accessibility: '',
    confidentiality: ''
  },
  newMentee: {
    ethnic: '',
    otherEthnic: '',
    pronoun: '',
    otherPronoun: '',
    goal: '',
    motivation: [],
    personality: [],
    confidentiality: ''
  },
  newMentor: {
    ethnic: '',
    otherEthnic: '',
    pronoun: '',
    otherPronoun: '',
    experienceAs: '',
    mentoringExperience: '',
    leadershipExperience: '',
    otherLeadershipExperience: '',
    enjoyAsMentor: '',
    areaOfExpertise: '',
    otherAreaOfExpertise: '',
    personality: [],
    confidentiality: ''
  },
  newCoachee: {
    ethnic: '',
    otherEthnic: '',
    pronoun: '',
    otherPronoun: '',
    leadershipChallenges: [],
    otherLeadershipChallenges: '',
    career: '',
    otherCareer: '',
    managementExperience: '',
    otherManagementExperience: '',
    leadershipStyle: '',
    otherLeadershipStyle: '',
    coachingGoal: '',
    leadershipGoal: '',
    personality: [],
    confidentiality: ''
  },
  newCoach: {
    ethnic: '',
    otherEthnic: '',
    pronoun: '',
    otherPronoun: '',
    industriesExperience: [],
    otherIndustriesExperience: '',
    roleExperience: [],
    otherRoleExperience: '',
    managementExperience: '',
    otherManagementExperience: '',
    leadershipExperience: '',
    otherLeadershipExperience: '',
    credentials: '',
    numberOfCoachingHours: '',
    experienceExtent: [],
    otherExperienceExtent: '',
    coachingStyle: '',
    otherCoachingStyle: '',
    groupCoaching: '',
    timeCommitment: '',
    training: '',
    otherTraining: '',
    personality: [],
    confidentiality: ''
  },
  mentee: {
    clientId: '',
    clientName: '',
    email: '',
    name: '',
    company: '',
    age: '',
    location: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    title: '',
    experience: '',
    personality: [],
    ethnic: '',
    pronoum: '',
    buildingInfluence: '',
    advancingCareer: '',
    negotiating: '',
    expressingYourself: '',
    publicSpeaking: '',
    leadership: '',
    workLifeBalance: '',
    buildingNetwork: '',
    navigatingBoundaries: '',
    managingEmotions: '',
    navigatingDiscrimination: '',
    managingConflict: '',
    imposterSyndrome: '',
    topics: [],
    becomeMentor: '',
    otherTopic: '',
    growth: '',
    otherGrowth: '',
    goal: '',
    confidentiality: ''
  },
  mentor: {
    clientId: '',
    clientName: '',
    email: '',
    linkedin: '',
    name: '',
    title: '',
    age: '',
    location: '',
    company: '',
    employment: '',
    areas: [],
    otherArea: '',
    experience: '',
    enjoyment: [],
    otherEnjoyment: '',
    expertise: [],
    otherExpertise: '',
    goal: '',
    personality: [],
    availability: '',
    otherAvailability: '',
    confidentiality: ''
  }
};
