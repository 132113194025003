import * as React from 'react';
import {
  Avatar,
  Typography,
  Grid,
  List,
  ListItem,
  Button,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Box
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StopIcon from '@mui/icons-material/Stop';
import CheckIcon from '@mui/icons-material/Check';
import GradingIcon from '@mui/icons-material/Grading';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useDispatch, useSelector } from 'react-redux';
import { findCoachingSessionsByUserId, getCoachingSessions } from '../../redux/actions/coachingSessions';
import { useEffect } from 'react';
import FirstSessionEvaluation from './firstEvaluation/FirstSessionEvaluation';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Colors } from '../../enums/enums';
import TermsAndConditionsAgreement from './termsAndConditions/TermsAndConditionsAgreement';
import { ICoachingSessionFullDTO } from '../Coaching/CoachingSessionFullDTO';
import {
  fetchUserTaskAndResponses,
  fetchUserTaskAndResponsesNoGroup,
  getUserTasksAndResponses,
  setTasksCount
} from '../../redux/actions/tasks';
import BestHumanLogo from '../../assets/besthuman.png';
import { UserTasksAndResponses } from '../../models/userTasksAndResponses';

const useStyles = makeStyles({
  pulsingButton: {
    animation: '$pulse 1s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)'
    },
    '50%': {
      transform: 'scale(1.03)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
});

export default function Tasks() {
  const userId = localStorage.getItem('LOCELLE:USER');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openMeetAndGreatCoachingForm, setOpenMeetAndGreatCoachingForm] = React.useState(false);
  const [openTermsAndConditionsForm, setOpenTermsAndConditions] = React.useState(false);
  const [openFinalStart, setOpenFinalStart] = React.useState(false);
  const [openPilotStart, setOpenPilotStart] = React.useState(false);
  const [openOneOnOneFinalStart, setOpenOneOnOneFinalStart] = React.useState(false);
  const [openMidCohortStart, setOpenMidCohortStart] = React.useState(false);
  const history = useHistory();
  const sessions: ICoachingSessionFullDTO[] = useSelector(getCoachingSessions);
  // sorting sessions to get the groupId of the latest session
  const groupId = sessions?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]?.groupId;
  const userTasksAndResponses: UserTasksAndResponses = useSelector(getUserTasksAndResponses);

  //temporary measure for leaders to only see terms and conditions as task
  const leaderIds = sessions?.map((s) => s.groupDto.leader.id);
  const isLeader: boolean = leaderIds?.includes(userId);

  const {
    tasksCount,
    firstAssessment,
    termsAndConditions,
    midCohortGroupAssessment,
    finalGroupAssessment,
    pilotGroupAssessment,
    oneOnOneFinalAssessment
  } = userTasksAndResponses;

  useEffect(() => {
    if (!groupId && userId) {
      dispatch(fetchUserTaskAndResponsesNoGroup(userId));
    }
    if (userId && groupId) {
      dispatch(findCoachingSessionsByUserId(userId));
      dispatch(fetchUserTaskAndResponses(userId, groupId));
    }
  }, [dispatch, userId]);

  // dispatch(setTasksCount(tasksCount));

  // region - Conditions Check
  const todayDate: Date = new Date();
  // Task due date and time is at least 15 mins into due session
  const assessmentDue = (dateString: string): boolean => {
    return todayDate >= new Date(new Date(dateString).getTime() + 15 * 60000);
  };
  // Task is overdue if it's 2 days or more since task's session date
  const assessmentOverDue = (dateString: string): boolean => {
    return new Date(dateString) < new Date(todayDate.getTime() - 24 * 60 * 60 * 1000 * 2);
  };

  const handleClose = (): void => {
    if (!groupId) {
      dispatch(fetchUserTaskAndResponsesNoGroup(userId));
    }
    if (userId && groupId) {
      dispatch(fetchUserTaskAndResponses(userId, groupId));
    }
    setOpenMeetAndGreatCoachingForm(false);
    setOpenTermsAndConditions(false);
  };

  return (
    <>
      <div>
        <Grid container spacing={3} sx={{ mt: 1, ml: 1 }}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '22px', color: Colors.TextElevated }}>
              {userTasksAndResponses?.tasksCount === 0 ? 'All tasks are completed, good job!' : 'Your Tasks:'}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {userTasksAndResponses?.tasksCount > 0 && (
              <Box>
                <Typography sx={{ color: 'grey', display: 'flex', alignItems: 'center', whiteSpace: 'pre' }}>
                  <StopIcon sx={{ fontSize: '32px' }} color="success" /> - green button indicates an active task
                </Typography>
                <Typography
                  sx={{
                    mt: '-7px',
                    color: 'grey',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'pre'
                  }}
                >
                  <StopIcon sx={{ fontSize: '32px', color: Colors.LogoRed }} /> - red button signifies an overdue task
                </Typography>
                <Typography
                  sx={{
                    ml: '1px',
                    mt: '-2px',
                    color: 'grey',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'pre'
                  }}
                >
                  <Avatar sx={{ width: '23px', height: '23px', backgroundColor: '#5e984c', mr: '12px', ml: '4px' }}>
                    <DoneOutlineIcon sx={{ fontSize: '15px' }} />
                  </Avatar>
                  - green icon means a task has been completed
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={7}>
            <List sx={{ width: '100%' }}>
              {/*Terms and Conditions Survey*/}
              <Paper sx={{ mb: 1 }} elevation={2}>
                <ListItem
                  key="1"
                  secondaryAction={
                    <Stack direction="column" spacing={1}>
                      <Button
                        className={!termsAndConditions?.submitted ? classes.pulsingButton : ''}
                        disabled={!!termsAndConditions?.submitted}
                        startIcon={termsAndConditions?.submitted ? <CheckIcon /> : <></>}
                        sx={{ width: '215px' }}
                        variant="contained"
                        color="success"
                        onClick={() => setOpenTermsAndConditions(true)}
                      >
                        Sign Agreement
                      </Button>
                      {!!termsAndConditions?.submitted && (
                        <a
                          style={{ marginLeft: 40 }}
                          href="https://www.besthuman.io/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                        >
                          View Terms and Conditions
                        </a>
                      )}
                    </Stack>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: !!termsAndConditions?.submitted ? '#5e984c' : 'grey' }}>
                      {!!termsAndConditions?.submitted ? <DoneOutlineIcon /> : <GradingIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Terms and Conditions"
                    secondary={
                      <>
                        <span>Approximate time: 2 mins</span>
                        <br />
                        {termsAndConditions?.submitted && (
                          <span>Signed on: {new Date(termsAndConditions?.submissionDate).toDateString()}</span>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </Paper>

              {/*First Session*/}
              {firstAssessment?.exists && !isLeader && (
                <Paper sx={{ mb: 1 }} elevation={2}>
                  <ListItem
                    key="2"
                    secondaryAction={
                      <Button
                        className={
                          assessmentDue(firstAssessment?.dueDate) && !firstAssessment?.submitted
                            ? classes.pulsingButton
                            : ''
                        }
                        sx={{ width: '215px' }}
                        disabled={!assessmentDue(firstAssessment?.dueDate) || !!firstAssessment?.submitted}
                        startIcon={!!firstAssessment?.submitted ? <CheckIcon /> : <></>}
                        variant="contained"
                        color={assessmentOverDue(firstAssessment?.dueDate) ? 'error' : 'success'}
                        onClick={() => setOpenMeetAndGreatCoachingForm(true)}
                      >
                        Take Evaluation
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: !!firstAssessment?.submitted ? '#5e984c' : 'grey' }}>
                        {!!firstAssessment?.submitted ? <DoneOutlineIcon /> : <RateReviewIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="First Session Evaluation"
                      secondary={
                        <>
                          <span>Approximate time: 30 sec</span>
                          <br />
                          {firstAssessment?.exists && !firstAssessment?.submitted && (
                            <span>Submit on: {new Date(firstAssessment?.dueDate).toLocaleDateString()}</span>
                          )}
                          {firstAssessment?.exists && !!firstAssessment?.submitted && (
                            <span>Submitted on: {new Date(firstAssessment?.submissionDate).toDateString()}</span>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              )}

              {/*Mid-Session*/}
              {midCohortGroupAssessment?.exists && !isLeader && (
                <Paper sx={{ mb: 1 }} elevation={2}>
                  <ListItem
                    key="3"
                    secondaryAction={
                      <Button
                        sx={{ width: '215px' }}
                        variant="contained"
                        startIcon={!!midCohortGroupAssessment?.submitted ? <CheckIcon /> : <></>}
                        color={assessmentOverDue(midCohortGroupAssessment?.dueDate) ? 'error' : 'success'}
                        className={
                          assessmentDue(midCohortGroupAssessment?.dueDate) && !midCohortGroupAssessment?.submitted
                            ? classes.pulsingButton
                            : ''
                        }
                        disabled={
                          !assessmentDue(midCohortGroupAssessment?.dueDate) || !!midCohortGroupAssessment?.submitted
                        }
                        onClick={() => {
                          setOpenMidCohortStart(true);
                        }}
                      >
                        Submit Assessment
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: !!midCohortGroupAssessment?.submitted ? '#5e984c' : 'grey' }}>
                        {!!midCohortGroupAssessment?.submitted ? <DoneOutlineIcon /> : <AssessmentIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Mid-Cohort Assessment"
                      secondary={
                        <>
                          <span>Approximate time: 7 mins</span>
                          <br />
                          {midCohortGroupAssessment?.exists && !midCohortGroupAssessment?.submitted && (
                            <span>Submit on: {new Date(midCohortGroupAssessment?.dueDate).toLocaleDateString()}</span>
                          )}
                          {midCohortGroupAssessment?.exists && !!midCohortGroupAssessment?.submitted && (
                            <span>
                              Submitted on: {new Date(midCohortGroupAssessment?.submissionDate).toDateString()}
                            </span>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              )}

              {/*Final Session*/}
              {finalGroupAssessment?.exists && !isLeader && (
                <Paper sx={{ mb: 1 }} elevation={2}>
                  <ListItem
                    key="4"
                    secondaryAction={
                      <Button
                        sx={{ width: '215px' }}
                        startIcon={!!finalGroupAssessment?.submitted ? <CheckIcon /> : <></>}
                        className={
                          assessmentDue(finalGroupAssessment?.dueDate) && !finalGroupAssessment?.submitted
                            ? classes.pulsingButton
                            : ''
                        }
                        disabled={!assessmentDue(finalGroupAssessment?.dueDate) || !!finalGroupAssessment?.submitted}
                        variant="contained"
                        color={assessmentOverDue(finalGroupAssessment?.dueDate) ? 'error' : 'success'}
                        onClick={() => {
                          setOpenFinalStart(true);
                        }}
                      >
                        Submit Assessment
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: !!finalGroupAssessment?.submitted ? '#5e984c' : 'grey' }}>
                        {!!finalGroupAssessment?.submitted ? <DoneOutlineIcon /> : <ListAltIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Final Assessment"
                      secondary={
                        <>
                          <span>Approximate time: 7 mins</span>
                          <br />
                          {finalGroupAssessment?.exists && !finalGroupAssessment?.submitted && (
                            <span>Submit on: {new Date(finalGroupAssessment?.dueDate).toLocaleDateString()}</span>
                          )}
                          {finalGroupAssessment?.exists && !!finalGroupAssessment?.submitted && (
                            <span>Submitted on: {new Date(finalGroupAssessment?.submissionDate).toDateString()}</span>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              )}

              {/*Pilot Group Task */}
              {pilotGroupAssessment?.exists && !isLeader && (
                <Paper sx={{ mb: 1 }} elevation={2}>
                  <ListItem
                    key="4"
                    secondaryAction={
                      <Button
                        sx={{ width: '215px' }}
                        startIcon={!!pilotGroupAssessment?.submitted ? <CheckIcon /> : <></>}
                        className={
                          assessmentDue(pilotGroupAssessment?.dueDate) && !pilotGroupAssessment?.submitted
                            ? classes.pulsingButton
                            : ''
                        }
                        disabled={!assessmentDue(pilotGroupAssessment?.dueDate) || !!pilotGroupAssessment?.submitted}
                        variant="contained"
                        color={assessmentOverDue(pilotGroupAssessment?.dueDate) ? 'error' : 'success'}
                        onClick={() => {
                          setOpenPilotStart(true);
                        }}
                      >
                        Submit Assessment
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: !!pilotGroupAssessment?.submitted ? '#5e984c' : 'grey' }}>
                        {!!pilotGroupAssessment?.submitted ? <DoneOutlineIcon /> : <ListAltIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Pilot Coaching Group Assessment"
                      secondary={
                        <>
                          <span>Approximate time: 7 mins</span>
                          <br />
                          {pilotGroupAssessment?.exists && !pilotGroupAssessment?.submitted && (
                            <span>Submit on: {new Date(pilotGroupAssessment?.dueDate).toLocaleDateString()}</span>
                          )}
                          {pilotGroupAssessment?.exists && !!pilotGroupAssessment?.submitted && (
                            <span>Submitted on: {new Date(pilotGroupAssessment?.submissionDate).toDateString()}</span>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              )}

              {/* 1:1 final survey Task */}
              {oneOnOneFinalAssessment?.exists && !isLeader && (
                <Paper sx={{ mb: 1 }} elevation={2}>
                  <ListItem
                    key="4"
                    secondaryAction={
                      <Button
                        sx={{ width: '215px' }}
                        startIcon={!!oneOnOneFinalAssessment?.submitted ? <CheckIcon /> : <></>}
                        className={
                          assessmentDue(oneOnOneFinalAssessment?.dueDate) && !oneOnOneFinalAssessment?.submitted
                            ? classes.pulsingButton
                            : ''
                        }
                        disabled={
                          !assessmentDue(oneOnOneFinalAssessment?.dueDate) || !!oneOnOneFinalAssessment?.submitted
                        }
                        variant="contained"
                        color={assessmentOverDue(oneOnOneFinalAssessment?.dueDate) ? 'error' : 'success'}
                        onClick={() => {
                          setOpenOneOnOneFinalStart(true);
                        }}
                      >
                        Submit Feedback
                      </Button>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: !!oneOnOneFinalAssessment?.submitted ? '#5e984c' : 'grey' }}>
                        {!!oneOnOneFinalAssessment?.submitted ? <DoneOutlineIcon /> : <ListAltIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Final Session Feedback"
                      secondary={
                        <>
                          <span>Approximate time: 6 mins</span>
                          <br />
                          {oneOnOneFinalAssessment?.exists && !oneOnOneFinalAssessment?.submitted && (
                            <span>Submit on: {new Date(oneOnOneFinalAssessment?.dueDate).toLocaleDateString()}</span>
                          )}
                          {oneOnOneFinalAssessment?.exists && !!oneOnOneFinalAssessment?.submitted && (
                            <span>
                              Submitted on: {new Date(oneOnOneFinalAssessment?.submissionDate).toDateString()}
                            </span>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              )}
            </List>
          </Grid>

          {/*commenting out for now*/}
          {/* Leadership Development Plan */}
          {/*<Grid item xs={12}>*/}
          {/*  <Divider sx={{ mb: 3 }} />*/}
          {/*  <Typography sx={{ mb: 1, mt: 3, ml: 2, fontWeight: 'bold', fontSize: '22px', color: 'darkolivegreen' }}>*/}
          {/*    Leadership Development Plan*/}
          {/*  </Typography>*/}
          {/*  <Typography sx={{ ml: 2 }}>*/}
          {/*    Please download Leadership Development Plan template, fill it out and submit after you have finished all*/}
          {/*    your sessions:*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}

          {/*<Grid item xs={7}>*/}
          {/*  <List sx={{ width: '100%' }}>*/}
          {/*    <Paper sx={{ mb: 5 }} elevation={2}>*/}
          {/*      <ListItem*/}
          {/*        key="5"*/}
          {/*        secondaryAction={*/}
          {/*          <>*/}
          {/*            <Button*/}
          {/*              href="/assets/leadership-development-plan/YOUR_NAME__Leadership_Development_Plan_BestHuman_2023.pptx"*/}
          {/*              download*/}
          {/*              endIcon={<DownloadIcon />}*/}
          {/*              variant="outlined"*/}
          {/*              color="success"*/}
          {/*              sx={{ mr: 3 }}*/}
          {/*            >*/}
          {/*              Download Plan*/}
          {/*            </Button>*/}
          {/*            <Button*/}
          {/*              disabled={!assessmentDue(finalAssessmentSubmissionDate)}*/}
          {/*              href="mailto:team@locelle.com"*/}
          {/*              endIcon={<SendIcon />}*/}
          {/*              variant="contained"*/}
          {/*              color="success"*/}
          {/*            >*/}
          {/*              Submit Plan*/}
          {/*            </Button>*/}
          {/*          </>*/}
          {/*        }*/}
          {/*        sx={{ display: 'flex', justifyContent: 'start' }}*/}
          {/*      >*/}
          {/*        <ListItemAvatar>*/}
          {/*          <Avatar sx={{ backgroundColor: '#5e984c' }}>*/}
          {/*            <AssignmentIcon />*/}
          {/*          </Avatar>*/}
          {/*        </ListItemAvatar>*/}
          {/*        <ListItemText*/}
          {/*          primary="Leadership Development Plan"*/}
          {/*          secondary={*/}
          {/*            <>*/}
          {/*              <span>Approximate time: 10 mins</span>*/}
          {/*              <br />*/}
          {/*              {finalAssessmentSubmissionDate && (*/}
          {/*                <span>Submit on: {new Date(finalAssessmentSubmissionDate).toLocaleDateString()}</span>*/}
          {/*              )}*/}
          {/*            </>*/}
          {/*          }*/}
          {/*        />*/}
          {/*      </ListItem>*/}
          {/*    </Paper>*/}
          {/*  </List>*/}
          {/*</Grid>*/}
        </Grid>

        {/* Mid-Cohort Assessment Start dialog */}
        <Dialog open={openMidCohortStart} onClose={() => setOpenMidCohortStart(false)}>
          <DialogContent sx={{ height: '650px', width: '600px', mt: '50px' }}>
            <Stack direction="column" sx={{ px: '25px' }} alignContent="center">
              <Typography sx={{ mt: '-20px', pb: 1, color: 'grey', fontSize: '1.5rem' }}>
                Mid-Cohort Assessment Survey
              </Typography>
              <Typography>Approximate completion time: 7 minutes</Typography>
              <img
                style={{ width: '100%', marginLeft: '-20px', marginBottom: '30px', marginTop: '40px' }}
                src={BestHumanLogo}
              />
              <Typography sx={{ fontSize: '20px', mb: 2, mt: 3 }}>
                Congrats! You have now completed your first set of sessions.
              </Typography>
              <Typography sx={{ color: 'grey', mb: 5 }}>
                Thank you for taking this next step in your career with us. Now it's time to reflect on your experience
                and share your feedback.
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ height: '50px', fontSize: '1rem', mt: '50px' }}
                onClick={() => {
                  history.push(`/mid-cohort-assessment/${userId}/${midCohortGroupAssessment?.taskId}/${groupId}`);
                }}
              >
                Complete Form
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Final Assessment Start dialog */}
        <Dialog open={openFinalStart} onClose={() => setOpenFinalStart(false)}>
          <DialogContent sx={{ height: '650px', width: '600px', mt: '50px' }}>
            <Stack direction="column" sx={{ px: '25px' }} alignContent="center">
              <Typography sx={{ mt: '-20px', pb: 1, color: 'grey', fontSize: '1.5rem' }}>
                Final Assessment Survey
              </Typography>
              <Typography>Approximate completion time: 7 minutes</Typography>
              <img
                style={{ width: '100%', marginLeft: '-20px', marginBottom: '30px', marginTop: '40px' }}
                src={BestHumanLogo}
              />
              <Typography sx={{ fontSize: '20px', mb: 2, mt: 3 }}>
                That's a wrap! Congrats you have now completed your sessions
              </Typography>
              <Typography sx={{ color: 'grey', mb: 5 }}>
                Thank you for taking this next step in your career with us. Now it's time to reflect on your experience.
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ height: '50px', fontSize: '1rem', mt: '50px' }}
                onClick={() => {
                  history.push(`/final-assessment/${userId}/${finalGroupAssessment?.taskId}/${groupId}`);
                }}
              >
                Complete Form
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Pilot Group Assessment Start dialog */}
        <Dialog open={openPilotStart} onClose={() => setOpenPilotStart(false)}>
          <DialogContent sx={{ height: '650px', width: '600px', mt: '50px' }}>
            <Stack direction="column" sx={{ px: '25px' }} alignContent="center">
              <Typography sx={{ mt: '-20px', pb: 1, color: 'grey', fontSize: '1.5rem' }}>
                Pilot Coaching Group Assessment
              </Typography>
              <Typography>Approximate completion time: 7 minutes</Typography>
              <img
                style={{ width: '100%', marginLeft: '-20px', marginBottom: '30px', marginTop: '40px' }}
                src={BestHumanLogo}
              />
              <Typography sx={{ fontSize: '20px', mb: 2, mt: 3 }}>
                That's a wrap! Congrats you have now completed your first set of sessions
              </Typography>
              <Typography sx={{ color: 'grey', mb: 5 }}>
                Thank you for trusting us to bring you peer learning + executive coaching.
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ height: '50px', fontSize: '1rem', mt: '50px' }}
                onClick={() => {
                  history.push(`/pilot-assessment/${userId}/${pilotGroupAssessment?.taskId}/${groupId}`);
                }}
              >
                Complete Form
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* 1:1 final survey Assessment Start dialog */}
        <Dialog open={openOneOnOneFinalStart} onClose={() => setOpenOneOnOneFinalStart(false)}>
          <DialogContent sx={{ height: '650px', width: '600px', mt: '50px' }}>
            <Stack direction="column" sx={{ px: '25px' }} alignContent="center">
              <Typography sx={{ mt: '-20px', pb: 1, color: 'grey', fontSize: '1.5rem' }}>
                Final Session Feedback
              </Typography>
              <Typography>Approximate completion time: 6 minutes</Typography>
              <img
                style={{ width: '100%', marginLeft: '-20px', marginBottom: '30px', marginTop: '40px' }}
                src={BestHumanLogo}
              />
              <Typography sx={{ fontSize: '20px', mb: 2, mt: 3 }}>
                That's a wrap! Congrats you have now completed your sessions.
              </Typography>
              <Typography sx={{ color: 'grey', mb: 5 }}>
                Thank you for taking this next step in your career with us. <br />
                Now it's time to reflect on your experience.
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ height: '50px', fontSize: '1rem', mt: '50px' }}
                onClick={() => {
                  history.push(`/one-on-one-final/${userId}/${oneOnOneFinalAssessment?.taskId}/${groupId}`);
                }}
              >
                Complete Form
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* First Session Evaluation */}
        <FirstSessionEvaluation
          isOpen={openMeetAndGreatCoachingForm}
          handleClose={handleClose}
          taskId={firstAssessment?.taskId}
          groupId={groupId}
        />

        {/* Terms and Conditions Agreement */}
        <TermsAndConditionsAgreement isOpen={openTermsAndConditionsForm} userId={userId} handleClose={handleClose} />
      </div>
    </>
  );
}