import React from 'react';
import { Grid, FormControlLabel, FormControl, FormLabel, FormHelperText, Radio, RadioGroup, TextField, Button, FormGroup } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from "little-state-machine";
import { updateAction } from "../../actions";
import { useForm, Controller } from 'react-hook-form';

interface IFormProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Section2: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const { register, handleSubmit, getValues, watch, control, formState: { errors }} = useForm({
    defaultValues: state?.formDetails?.mentor,
  });

  const watchAreas = watch('areas');
  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({
      formDetails: {
        mentor: partialData
      } 
    } as any);    
    setStep(step -1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({
      formDetails: {
        mentor: data
      } 
    } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Company name and industry *</FormLabel>
            <Controller
              render={({ field }) => 
                <TextField {...field} placeholder='Your answer' fullWidth variant='outlined'/>
              }
              name="company"
              control={control}
              rules={ { required: true } }
            />
            {errors?.company?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>I am a(n) *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['Entrepreneur', 'Employee at a company', 'Both', 'Other'].map((employment, index) =>  (
                    <FormControlLabel key={index} value={employment} control={<Radio color='primary' />} label={employment} />
                  ))}
                </RadioGroup>
              )}
              name='employment'
              control={control}
              rules={ { required: true } }
            />
            {errors?.employment?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.formLabel}>Which of the following areas of business do you function in? *</FormLabel>
            <FormGroup row>        
            {['Accounting/Finance', 'Admin/Operations', 'Community/Partnerships', 'Engineering/IT/Dev', 'HR/Recruitment', 'Marketing/Communications', 'People/Culture', 'Sales/Growth', 'Strategy/Executive Leadership', 'Other'].map((area, index) => 
                  <Grid className={classes.checkboxContainer} item xs={12} key={index}>
                    <FormLabel className={classes.checkboxLabel} key={area}>
                      <input className={classes.checkbox} {...register('areas', { required: true })} type='checkbox' value={area} name='areas'/>
                      {area}
                    </FormLabel>
                  </Grid>
              )}
            </FormGroup>
            {(errors.areas as any)?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
          { watchAreas && watchAreas.includes('Other') &&
            <Grid item xs={12}>
              <Controller
                render={({ field }) => 
                  <TextField {...field} placeholder='Other *' required fullWidth variant='outlined'/>
                }
                name="otherArea"
                control={control}
              />
            </Grid>
          }
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant='outlined' color='secondary' onClick={() => handleBack()}>BACK</Button>
        </Grid>
                  
        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant='contained' color='secondary' type='submit'>NEXT</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section2;
