import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, LinearProgress, ThemeProvider, Grid, Paper } from '@material-ui/core';
import menteeForms from './mentees/menteeForms';
import mentorForms from './mentors/mentorForms';
import { theme, useStyles } from './styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../../../assets/logo.png';
import { StateMachineProvider, createStore } from 'little-state-machine';
import state from './state';
import { fetchCurrentSurveys, getSurveys } from '../../../redux/actions/surveys';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserType } from '../../../enums/enums';

interface FormStep {
  step: number;
  component: React.FC<any>;
}

createStore(
  {
    formDetails: state,
    feedbackDetails: state
  },
  { name: '', middleWares: [] }
);

export default function OnboardingForm() {
  const classes = useStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const urlPath = window.location.pathname.split('/');
  const type = urlPath.pop() || urlPath.pop();
  const isTypeMentee = UserType.Mentee === type;
  const formSections: FormStep[] = isTypeMentee ? menteeForms : mentorForms;
  const stepsCount = formSections.length - 1;
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();
  const surveys: any[] = useSelector(getSurveys);

  useEffect(() => {
    dispatch(fetchCurrentSurveys());
  }, [dispatch]);

  const surveysByClient = surveys.filter((client: { code: string }) => client.code === clientId);

  return (
    <StateMachineProvider>
      {!surveysByClient.length ? (
        <div style={{ marginLeft: '50%', marginTop: '30%' }}>
          <Typography>Loading</Typography>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.pageContent}>
            <Paper>
              <Grid className={classes.formContainer} container spacing={5} alignItems="center" justifyContent="center">
                <Grid style={{ paddingTop: 40 }} item>
                  <img src={Logo} alt="logo" className={classes.logo} />
                </Grid>

                {step < stepsCount && (
                  <Grid className={classes.headerBlock} item xs={12}>
                    <Typography variant="h4" align="center" component="h1" gutterBottom>
                      {isTypeMentee ? 'Mentor moments: Mentees' : 'Interested in becoming a Mentor with Locelle?'}
                    </Typography>
                    <Typography variant="h6" align="center" component="h2" gutterBottom>
                      {surveysByClient[0].name}
                    </Typography>
                    {step === 1 && (
                      <Typography paragraph align="center">
                        {isTypeMentee
                          ? 'To better identify areas of growth and to find a suitable mentor match.'
                          : 'Mentors will get the chance to use their experience in helping mentees grow and in turn might even find that they themselves grow as well.'}
                      </Typography>
                    )}
                    {step === 1 && (
                      <Typography paragraph align="center">
                        Approximate completion time: 2 minutes
                      </Typography>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={5} alignItems="center" justifyContent="center">
                    {formSections.map(
                      (formSection: FormStep, index: number) =>
                        formSection.step === step && (
                          <formSection.component
                            key={index}
                            step={step}
                            setStep={setStep}
                            client={surveysByClient[0]}
                          />
                        )
                    )}

                    {step <= stepsCount && (
                      <Grid item xs={12}>
                        <Typography component="h6" align="right">
                          Page {step} of {stepsCount}
                          <LinearProgress variant="determinate" color="secondary" value={(step / stepsCount) * 100} />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </ThemeProvider>
      )}
    </StateMachineProvider>
  );
}
