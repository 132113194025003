import api from '../../services/api';

export enum ContentActionTypes {
  FETCH = 'FILES_FETCH',
  FETCH_LEARNING_TOOLS = 'FETCH_LEARNING_TOOLS'
}

const baseUrl = '/content';

export function fetchContentFiles() {
  return function (dispatch) {
    return api.get(`${baseUrl}/file`).then(({ data }) => {
      dispatch({
        type: ContentActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchLearningTools() {
  return function (dispatch) {
    return api.get(`${baseUrl}/learning-tool`).then(({ data }) => {
      dispatch({
        type: ContentActionTypes.FETCH_LEARNING_TOOLS,
        payload: data
      });
    });
  };
}

// selectors
export const getFiles = (state) => state.content.files;
export const getLearningTools = (state) => state.content.learningTools;
