import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { TransitionProps } from '@material-ui/core/transitions';
import JitsiMeetingRoom from './JitsiMeetingRoom';
import { Session } from '../../models/session';
import { Box, Button, Dialog, Fab, Tooltip } from '@mui/material';
import NoteEditor from './NoteEditor';
import { NoteTypes, UserType } from '../../enums/enums';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: '70px',
      marginRight: '0px',
      position: 'absolute',
      backgroundColor: '#040404'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    }
  })
);

interface Props {
  mentor: { id: string; name: string; email: string };
  accessViaPortal: boolean;
  session: Session;
  openNoteEditor: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MeetingRoomFullScreenDialog({ mentor, session }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [preMeeting, setPreMeeting] = useState(true);

  const currentUser = localStorage.getItem('LOCELLE:USER_TYPE');
  const [notesAllowed, setNotesAllowed] = useState(false);
  const [notesOpened, setNotesOpened] = useState(false);
  const [closeMeetingDialogOpen, setCloseMeetingDialogOpen] = useState(false);

  /* OnInit */
  useEffect(() => {
    setNotesAllowed(UserType.Mentee === currentUser);
  }, []);

  const handleClickOpen = () => {
    setNotesOpened(false);
    setOpen(true);
    if (notesAllowed) {
      setNotesOpened(true);
    }
  };

  const handleClose = () => {
    setNotesOpened(false);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const handleClosePreMeeting = () => {
    setPreMeeting(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          mr: 1,
          color: 'white',
          backgroundColor: '#342e40',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#512d6e',
            color: 'white'
          }
        }}
        onClick={handleClickOpen}
      >
        Join Session
      </Button>
      {open && (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <Tooltip title="Leave the meeting" arrow>
            <Fab
              variant="extended"
              size="small"
              sx={{
                backgroundColor: '#B7412F',
                color: 'white',
                position: 'absolute',
                top: '1%',
                left: '1%'
              }}
              onClick={() => setCloseMeetingDialogOpen(true)}
            >
              <CloseIcon />
            </Fab>
          </Tooltip>
          {notesAllowed && !notesOpened && (
            <Tooltip title="Open notes" arrow>
              <Fab
                variant="extended"
                color="secondary"
                sx={{
                  position: 'absolute',
                  top: '90px',
                  right: '11px'
                }}
                onClick={() => setNotesOpened(true)}
              >
                <FormatListBulletedIcon />
              </Fab>
            </Tooltip>
          )}
          <Box sx={{ p: 0, height: '100%' }}>
            <Box
              sx={{
                width: notesOpened ? '60%' : '100%',
                height: '100%',
                display: 'inline-block'
              }}
            >
              {preMeeting && (
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
              )}
              <JitsiMeetingRoom
                mentor={mentor}
                accessViaPortal
                handleClose={handleClose}
                hidePreMeetingOptions={handleClosePreMeeting}
              />
            </Box>
            {notesAllowed && notesOpened && (
              <Box
                sx={{
                  width: '40%',
                  height: '100%',
                  display: 'inline-block',
                  verticalAlign: 'top'
                }}
              >
                <NoteEditor
                  noteType={NoteTypes.Mentoring}
                  sessionId={session.id}
                  stage={session.stage}
                  closeBtnOnLeft={true}
                  onClose={() => setNotesOpened(false)}
                />
              </Box>
            )}
          </Box>
        </Dialog>
      )}
      {closeMeetingDialogOpen && (
        <ConfirmationDialog
          isOpen={closeMeetingDialogOpen}
          title="Leave the meeting?"
          message="Are you sure you want to leave the meeting?"
          onConfirmBtn={() => {
            setCloseMeetingDialogOpen(false);
            handleClose();
          }}
          onCancelBtn={() => setCloseMeetingDialogOpen(false)}
        />
      )}
    </>
  );
}
