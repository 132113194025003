import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  TextField,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { IFormProps } from './section.interface';

const Section4: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentee
  });

  const classes = useStyles();
  const watchTopics = watch('growth');

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { mentee: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentee: data } } as any);
    setStep(step + 1);
  };

  const scaleController = (name: any) => {
    return (
      <Controller
        render={({ field }) => (
          <RadioGroup className={classes.horizontalRadioGroup} row {...field}>
            {['1', '2', '3', '4', '5'].map((v, index) => (
              <FormControlLabel
                key={index}
                value={v}
                control={<Radio color="primary" />}
                label={v}
                labelPlacement="top"
              />
            ))}
          </RadioGroup>
        )}
        name={name}
        control={control}
        rules={{ required: true }}
      />
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContainer} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How good are you at building influence at work? (5 is very good) ie. being a reliable resource, people
              listen to you easily, etc. *
            </FormLabel>
            {scaleController('buildingInfluence')}
            {errors?.buildingInfluence?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident are you when it comes to advancing your career (5 is a lot) *
            </FormLabel>
            {scaleController('advancingCareer')}
            {errors?.advancingCareer?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How good are you on negotiating for what you want? (5 is very good) ie. time off, salary, role change,
              etc. *
            </FormLabel>
            {scaleController('negotiating')}
            {errors?.negotiating?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident are you in expressing yourself? (5 is very confident) *
            </FormLabel>
            {scaleController('expressingYourself')}
            {errors?.expressingYourself?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident do you feel speaking in front of large groups of people? (5 is very confident) *
            </FormLabel>
            {scaleController('publicSpeaking')}
            {errors?.publicSpeaking?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident do you feel stepping into leadership? (5 is very confident) *
            </FormLabel>
            {scaleController('leadership')}
            {errors?.leadership?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What's your work/life balance like? (5 is excellent) *</FormLabel>
            {scaleController('workLifeBalance')}
            {errors?.workLifeBalance?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident are you in building a meaningful network to help you grow? (5 is very confident) *
            </FormLabel>
            {scaleController('buildingNetwork')}
            {errors?.buildingNetwork?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Are you confident in navigating boundaries in the workplace? (5 is very confident) ie. knowing when to be
              assertive. *
            </FormLabel>
            {scaleController('navigatingBoundaries')}
            {errors?.navigatingBoundaries?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How well can you manage emotions in the workplace? (5 is very well) *
            </FormLabel>
            {scaleController('managingEmotions')}
            {errors?.managingEmotions?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How confident are you in navigating discrimination and stereotypes? (5 is very confident) *
            </FormLabel>
            {scaleController('navigatingDiscrimination')}
            {errors?.navigatingDiscrimination?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              What's your confidence level in managing conflict? (5 is very confident) *
            </FormLabel>
            {scaleController('managingConflict')}
            {errors?.managingConflict?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How much does 'imposter syndrome' affect your ability to perform? (5 is a lot) *
            </FormLabel>
            {scaleController('imposterSyndrome')}
            {errors?.imposterSyndrome?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              What are you hoping to take away from this mentorship? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    'Accountability',
                    'Gain clarity on career path',
                    'Having a trusted advisor for ongoing support',
                    'Step into leadership (non-people management)',
                    'Step into management role (people leadership)',
                    'Upskill/level up in my field',
                    'Other'
                  ].map((path, index) => (
                    <FormControlLabel key={index} value={path} control={<Radio color="primary" />} label={path} />
                  ))}
                </RadioGroup>
              )}
              name="growth"
              control={control}
              rules={{ required: true }}
            />
            {errors?.growth?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchTopics && watchTopics.includes('Other') && (
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField {...field} placeholder="Other *" required fullWidth variant="outlined" />
                )}
                name="otherGrowth"
                control={control}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              If you could change one thing about your career in the coming year, what would it be (we encourage you to
              think bold) *
            </FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your answer" fullWidth variant="outlined" />}
              name="goal"
              control={control}
              rules={{ required: true }}
            />
            {errors?.goal?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Last but not least, are you interested in becoming a mentor? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    'Yes - say no to imposter syndrome (especially if you bring over 10 years of experience in the workplace)',
                    'No - not into it',
                    'Maybe - will assess after I have been a mentee'
                  ].map((becomeMentor, index) => (
                    <FormControlLabel
                      key={index}
                      value={becomeMentor}
                      control={<Radio color="primary" />}
                      label={becomeMentor}
                    />
                  ))}
                </RadioGroup>
              )}
              name="becomeMentor"
              control={control}
              rules={{ required: true }}
            />
            {errors?.becomeMentor?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section4;
