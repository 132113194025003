import React from 'react';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, TextField } from '@mui/material';
import { IFormProps } from '../NewCoacheeSurvey';

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.newCoachee
  });
  const classes = useStyles();
  const watchEthnic = watch('ethnic');
  const watchPronoun = watch('pronoun');
  const watchLeadershipChallenges = watch('leadershipChallenges');
  const watchCareer = watch('career');
  const watchManagementExperience = watch('managementExperience');
  const watchLeadershipStyle = watch('leadershipStyle');

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 1: ', data);
    actions.updateAction({ formDetails: { newCoachee: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Our community is diverse and we are proud of it. We appreciate it if you can share your ethnic origin. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'European',
                    'Hispanic/Latinx',
                    'South American',
                    'African',
                    'Indigenous',
                    'White/Caucasian',
                    'Arab',
                    'Asian/South Asian',
                    'Prefer not to answer',
                    'Other'
                  ].map((ethnic, index) => (
                    <FormControlLabel key={index} value={ethnic} control={<Radio color="primary" />} label={ethnic} />
                  ))}
                </RadioGroup>
              )}
              name="ethnic"
              control={control}
              rules={{ required: true }}
            />
            {errors?.ethnic?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchEthnic && watchEthnic?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherEthnic"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              We are intentional about building a diverse community. We appreciate if you would be willing to share how
              you identify yourself. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['She/Her', 'He/Him', 'They/Them', 'Ze/Hir', 'Xe/Xem', 'Prefer not to answer', 'Other'].map(
                    (pronoun, index) => (
                      <FormControlLabel
                        key={index}
                        value={pronoun}
                        control={<Radio color="primary" />}
                        label={pronoun}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="pronoun"
              control={control}
              rules={{ required: true }}
            />
            {errors?.pronoun?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchPronoun && watchPronoun?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      placeholder="Your answer *"
                      required
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherPronoun"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>
              What leadership challenges are you looking to navigate? (select all that apply) *
            </FormLabel>
            <FormGroup>
              {[
                'Give and get feedback',
                'Building influence with leadership and peers',
                'Conflict resolution',
                'Uncovering personal leadership style',
                'Building confidence',
                'Situational leadership',
                'Become a better people leader',
                'Challenge myself to grow through accountability',
                'Other'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('leadershipChallenges', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="leadershipChallenges"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.leadershipChallenges?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.leadershipChallenges?.message}</FormHelperText>
          </FormControl>
          {watchLeadershipChallenges && watchLeadershipChallenges?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherLeadershipChallenges"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>Where are you in your career? *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'C-Suite',
                    'Experienced Leader',
                    'New People Manager',
                    'High Growth Potential Individual Contributor',
                    'Other'
                  ].map((career, index) => (
                    <FormControlLabel key={index} value={career} control={<Radio color="primary" />} label={career} />
                  ))}
                </RadioGroup>
              )}
              name="career"
              control={control}
              rules={{ required: true }}
            />
            {errors?.career?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchCareer && watchCareer?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherCareer"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              How many years of people management experience do you have? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['10+ years', '5+ years', '1-4 years', 'No experience', 'Other'].map(
                    (managementExperience, index) => (
                      <FormControlLabel
                        key={index}
                        value={managementExperience}
                        control={<Radio color="primary" />}
                        label={managementExperience}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="managementExperience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.managementExperience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchManagementExperience && watchManagementExperience?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherManagementExperience"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your personality style? (Select all that apply): *
            </FormLabel>
            <FormGroup>
              {[
                'Motivational',
                'Upbeat',
                'Calm',
                'Direct',
                'Realist',
                'Compassionate',
                'Extroverted',
                'Introverted'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('personality', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="personality"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.personality?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.personality?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your current leadership style? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'Autocratic',
                    'Pacesetting',
                    'Transformational',
                    'Coaching',
                    'Democratic',
                    'Affiliative',
                    'Delegative',
                    'Other'
                  ].map((leadershipStyle, index) => (
                    <FormControlLabel
                      key={index}
                      value={leadershipStyle}
                      control={<Radio color="primary" />}
                      label={leadershipStyle}
                    />
                  ))}
                </RadioGroup>
              )}
              name="leadershipStyle"
              control={control}
              rules={{ required: true }}
            />
            {errors?.leadershipStyle?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchLeadershipStyle && watchLeadershipStyle?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherLeadershipStyle"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              In the best detail, please describe what you are hoping to get out of coaching? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  sx={{ ml: 1 }}
                  multiline
                  rows={4}
                  placeholder="Your answer"
                  fullWidth
                  variant="outlined"
                  {...field}
                />
              )}
              name="coachingGoal"
              control={control}
              rules={{ required: true }}
            />
            {errors?.coachingGoal?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              In the best detail, please share your goals as a leader? *{' '}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  sx={{ ml: 1 }}
                  multiline
                  rows={4}
                  placeholder="Your answer"
                  fullWidth
                  variant="outlined"
                  {...field}
                />
              )}
              name="leadershipGoal"
              control={control}
              rules={{ required: true }}
            />
            {errors?.leadershipGoal?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;
