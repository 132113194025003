import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateSession } from '../../../redux/actions/sessions';
import {
  Box,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import StarIcon from '@mui/icons-material/Star';
import { sendSurveysResponse } from '../../../redux/actions/surveysResponse';
import { useAuth0 } from '@auth0/auth0-react';
import { Session } from '../../../models/session';
import { getInternalUser } from '../../../redux/actions/user';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { UserType } from '../../../enums/enums';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  currentSession: Session;
  refreshSessionList: () => void;
}

const MeetAndGreetFeedback = (props: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState<number | null>(null);
  const [hover, setHover] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const { user } = useAuth0();
  const internalUser = useSelector(getInternalUser);
  const currentUserType = localStorage.getItem('LOCELLE:USER_TYPE');

  const userStatusComplete = (type) => (UserType.Mentor === type ? 'completementor' : 'completementee');
  const submitFeedback = (data) => {
    try {
      setLoading(true);
      setTimeout(() => {
        const currentSession = props.currentSession;

        data.email = user.nickname;
        data.match = currentSession.description;
        data.name = internalUser?.name;

        const feedback = {
          type: 'match',
          clientId: currentSession.clientId,
          surveyId: currentSession.feedback_form,
          email: user.email,
          sessionId: currentSession.id,
          formResponse: data
        };
        dispatch(sendSurveysResponse(feedback));

        const statusUpdate = currentSession.status === 'CONFIRMED' ? 'FEEDBACK' : 'COMPLETED';
        const doNotContinue = data.continue === 'no';
        const reason = doNotContinue ? ('Other' === data.options ? data.reason : data.options) : null;

        const updateSessionObject = {
          id: currentSession.id,
          matchId: currentSession.matchId,
          date: currentSession.date,
          status: statusUpdate,
          origin: doNotContinue ? 'REMATCH' : '',
          reason
        };

        updateSessionObject[userStatusComplete(currentUserType)] = true;
        dispatch(updateSession(updateSessionObject));
        setLoading(false);
        setSuccess(true);
        // props.handleClose();
      }, 3000);

      setTimeout(() => {
        props.handleClose();
      }, 5000);
    } catch (e) {
      alert(e);
    }
  };

  const labels: { [index: string]: string } = {
    1: 'Very Poor',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Very Good'
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  const { register, handleSubmit, getValues, watch, control, formState } = useForm({
    defaultValues: {
      rating: null,
      continue: null,
      options: null,
      reason: null
    }
  });

  const { isSubmitting } = formState;

  const watchAnswer = watch('continue');

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      // aria-labelledby="alert-dialog-title"
      // aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Feedback {props?.currentSession?.stage}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitFeedback)}>
          <FormControl>
            <FormLabel style={{ marginBottom: '5px', marginRight: '10px' }}>
              How would you rate your session? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Rating
                  {...field}
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
              )}
              name="rating"
              control={control}
            />
            {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
          </FormControl>
          <FormControl style={{ marginTop: '15px' }}>
            <FormLabel>Would like to continue with this match? *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {['yes', 'no'].map((v, index) => (
                    <FormControlLabel
                      style={{ marginLeft: '0px' }}
                      key={index}
                      value={v}
                      control={<Radio color="primary" />}
                      label={v}
                      labelPlacement="bottom"
                    />
                  ))}
                </RadioGroup>
              )}
              name="continue"
              control={control}
              rules={{ required: true }}
            />
            {/* {errors?.continue?.type === 'required' && <FormHelperText style={{color: 'red'}}>Required</FormHelperText>} */}
          </FormControl>
          {watchAnswer && watchAnswer.includes('no') && (
            <>
              <FormControl style={{ marginTop: '10px' }}>
                <FormLabel>Please share why? *</FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      {[
                        'Mentor/mentee was disengaged (not present/interested)',
                        'The match-fit (does not have relevant experience or background)',
                        'Personality',
                        'Other'
                      ].map((path, index) => (
                        <FormControlLabel key={index} value={path} control={<Radio color="primary" />} label={path} />
                      ))}
                    </RadioGroup>
                  )}
                  name="options"
                  control={control}
                  rules={{ required: true }}
                />
              </FormControl>

              <Controller
                render={({ field }) => (
                  <TextField
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                    {...field}
                    placeholder="If Other - please share why *"
                    fullWidth
                    variant="outlined"
                    disabled={'Other' !== watch('options')}
                  />
                )}
                name="reason"
                control={control}
                rules={{ required: false }}
              />
              <Typography sx={{ mt: '20px', mb: '40px' }}>
                NOTE: All your prescheduled sessions with your current match will be cancelled. Our team will get in
                touch with you accordingly.{' '}
              </Typography>
            </>
          )}
          {success ? (
            <Fab style={{ backgroundColor: '#91cd91', position: 'absolute', bottom: 15, right: '15px' }}>
              <CheckIcon />
            </Fab>
          ) : (
            <Button
              variant="outlined"
              style={{ position: 'absolute', bottom: '15px', right: '25px' }}
              type="submit"
              disabled={loading}
            >
              Submit feedback
            </Button>
          )}

          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: 'green',
                position: 'absolute',
                bottom: '10px',
                right: '50px'
              }}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>{/* <Button type='submit'>Send feedback</Button> */}</DialogActions>
    </Dialog>
  );
};

export default MeetAndGreetFeedback;
