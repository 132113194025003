import { Box, Card, CardContent, Grow, LinearProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Colors, SessionStageType } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { findCoachingSessionsByUserId, getCoachingSessions } from '../../redux/actions/coachingSessions';
import { format, parseISO } from 'date-fns';
import { ICoachingSessionFullDTO } from '../Coaching/CoachingSessionFullDTO';
import { CoachingSessionStatus } from '../Coaching/coaching.enum';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import StarsIcon from '@mui/icons-material/Stars';
import { useHistory } from 'react-router-dom';

const UserProgressBar = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const allSessions = useSelector(getCoachingSessions);
  const history = useHistory();

  useEffect(() => {
    dispatch(findCoachingSessionsByUserId(userId));
  }, [dispatch, userId]);

  const sessions: ICoachingSessionFullDTO[] = allSessions?.filter((s) =>
    s.stage === SessionStageType.Individual ? s.participantId === userId || s.groupDto.leader.id === userId : true
  );

  sessions?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const totalSessions = sessions.length;
  const completedSessions = sessions.filter(
    (session) => session?.status === CoachingSessionStatus.Completed || new Date(session?.date) < new Date()
  ).length;
  const progress = ((completedSessions + 1) / (totalSessions +1)) * 100;

  const activeStep = sessions.findIndex((session) => new Date(session?.date) >= new Date());

  // Custom Step Icon Component
  const CustomStepIcon = (props) => {
    const { active, completed } = props; // Destructure active and completed props

    return completed ? (
      <CheckCircleIcon
        sx={{
          color: 'lightgray',
          fontSize: '2.2rem',
          transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for the glow
          '&:hover': {
            boxShadow: '0 0 10px 5px rgba(238, 252, 27, 0.35)',
            transform: 'scale(1.1)',
            transition: 'transform 0.3s ease-in-out',
            borderRadius: '50%'
          }
        }}
      />
    ) : active ? ( // Use active prop directly here
      <StarsIcon
        sx={{
          color: Colors.ButtonGreen,
          fontSize: '2.5rem',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 0 10px 5px rgba(238, 252, 27, 0.35)',
            transform: 'scale(1.1)',
            transition: 'transform 0.3s ease-in-out',
            borderRadius: '50%'
          }
        }}
      />
    ) : (
      <SupervisedUserCircleIcon
        sx={{
          color: Colors.TextElevated,
          fontSize: '2.3rem',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 0 10px 5px rgba(238, 252, 27, 0.35)',
            transform: 'scale(1.1)',
            transition: 'transform 0.3s ease-in-out',
            borderRadius: '50%'
          }
        }}
      />
    );
  };

  return (
    <Card
      elevation={0}
      sx={{
        minWidth: '300px',
        backgroundColor: Colors.BackgroundMain,
        boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
        borderRadius: 10,
        maxWidth: '100%',
        '&:hover': {
          boxShadow: '0 0 10px 5px rgba(223, 245, 39, 0.19)'
        }
      }}
    >
      <CardContent>
        <Typography gutterBottom sx={{ fontSize: '1.2rem', ml: 3, color: Colors.TextElevated, fontWeight: 'bold' }}>
          Your Progress
        </Typography>
        <Box sx={{ width: '100%', mb: -4 }}>
          <Box sx={{ marginBottom: 3, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: '15px',
                  borderRadius: 3,
                  ml: 3,
                  backgroundColor: Colors.Active,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: Colors.TextElevated
                  }
                }}
              />
            </Box>
            <Box>
              <Typography variant="body2" sx={{ color: 'text.secondary', ml: 0.5 }}>{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step
                key={1}
                completed={true}
                sx={{
                  '&:hover .MuiStepLabel-labelContainer': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }}
              >
                <Grow in={true} style={{ transformOrigin: '0 0 0', cursor: 'pointer' }} {...{ timeout: 300 }}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    onClick={() => {
                      history.push(`/sessions`);
                    }}
                  >
                    <Typography sx={{ color: 'gray', marginBottom: '0.3rem', mt: -1 }}>Onboarding</Typography>
                  </StepLabel>
                </Grow>
              </Step>
              {sessions.map((session, index) => (
                <Step
                  key={session.id}
                  completed={
                    new Date(session?.date) < new Date() || session?.status === CoachingSessionStatus.Completed
                  }
                  sx={{
                    '&:hover .MuiStepLabel-labelContainer': {
                      transform: 'scale(1.1)',
                      transition: 'transform 0.3s ease-in-out',
                      borderRadius: 1
                    }
                  }}
                >
                  <Grow
                    in={true}
                    style={{ transformOrigin: '0 0 0', cursor: 'pointer' }}
                    {...{ timeout: 300 * (index + 1) }}
                  >
                    <StepLabel
                      StepIconComponent={(stepProps) => (
                        <CustomStepIcon
                          {...stepProps}
                          active={index === activeStep}
                          completed={
                            new Date(session?.date) < new Date() || session?.status === CoachingSessionStatus.Completed
                          }
                        />
                      )}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ...(activeStep === index && {
                          backgroundColor: Colors.SelectedDrawerBackground,
                          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                          borderRadius: 1,

                          p: 1
                        })
                      }}
                      onClick={() => {
                        history.push(`/sessions`);
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.TextElevated,
                          marginBottom: '0.3rem',
                          mt: -1,
                          fontSize: '0.9rem'
                        }}
                      >
                        {format(parseISO(session?.date), 'MMM d, yyyy')}
                      </Typography>
                      <Typography
                        sx={{
                          color:
                            new Date(session?.date) < new Date() || session?.status === CoachingSessionStatus.Completed
                              ? 'gray'
                              : 'green'
                        }}
                      >
                        {session?.topic
                          ? session?.topic
                          : session?.stage === SessionStageType.MeetAndGreet
                          ? 'First Session'
                          : session?.stage}
                      </Typography>
                    </StepLabel>
                  </Grow>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserProgressBar;