import React from 'react';
import { useParams } from 'react-router-dom';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Grid,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
// import clients from '../../clients';
import api from '../../../../../services/api';
import { UserType } from '../../../../../enums/enums';

interface IFormProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  client: { id: string; code: string; name: string; mentorsId: string; menteesId: string };
}

const Section4: React.FC<IFormProps> = ({ step, setStep, client }) => {
  const { clientId } = useParams<{ clientId: string }>();
  // const clientName = clients.filter(client => client.code === clientId)[0].name;
  // const client =  clients.filter(client => client.code === clientId)[0];
  const urlPath = window.location.pathname.split('/');
  const type = urlPath.pop() || urlPath.pop();

  const { state, actions } = useStateMachine({
    updateAction
  });

  const { handleSubmit, getValues, control, formState } = useForm({
    defaultValues: state?.formDetails?.mentor
  });

  const classes = useStyles();

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleComplete();
      setStep(step + 1);
    }
  }, [formState, state]);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({
      formDetails: {
        mentor: partialData
      }
    } as any);
    setStep(step - 1);
  };

  const handleComplete = () => {
    api.post('/surveys/response', {
      type: UserType.Mentor,
      client: client.name,
      clientId: client.id,
      surveyId: client.mentorsId,
      email: state.formDetails.mentor.email,
      formResponse: state.formDetails.mentor
    });
  };

  const onSubmit = (data: any) => {
    actions.updateAction({
      formDetails: {
        mentor: {
          ...data,
          clientId: clientId,
          clientName: client?.name
        }
      }
    } as any);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContainer} container spacing={5} alignItems="center" justifyContent="center">
        <Grid style={{ justifyContent: 'center', alignItems: 'center' }} item>
          <FormControl>
            <FormLabel className={classes.formLabel} style={{ textAlign: 'justify' }}>
              As a mentor:
              <li style={{ marginTop: '5px' }}>
                I consent to confidentiality and non disclosure regarding sessions with mentees.
              </li>
              <li style={{ marginTop: '5px' }}>I can confirm that the information provided above is true.</li>
              <li style={{ marginTop: '5px' }}>
                I can also confirm that I am providing advice with the best interest of the mentee and I am not
                providing information with the intent of promoting my service or making a sale.
              </li>
              <li style={{ marginTop: '5px' }}>
                I acknowledge that this is a volunteer opportunity. In exchange for my time, I get access to monthly
                events with Locelle and to join a community of leaders.
              </li>
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="agree"
                    control={<Radio color="primary" />}
                    label="I agree"
                    labelPlacement="end"
                  />
                </RadioGroup>
              )}
              name="confidentiality"
              control={control}
              rules={{ required: true }}
            />
            {formState.errors?.confidentiality?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section4;
