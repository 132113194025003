import React from 'react';
import { useParams } from 'react-router-dom';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import api from '../../../../../services/api';
import { UserType } from '../../../../../enums/enums';
import { IFormProps } from './section.interface';

interface IFormClientProps extends IFormProps {
  client: { id: string; code: string; name: string; mentorsId: string; menteesId: string };
}

const Section5: React.FC<IFormClientProps> = ({ step, setStep, client }) => {
  const { clientId } = useParams<{ clientId: string }>();

  const { state, actions } = useStateMachine({
    updateAction
  });

  const { handleSubmit, getValues, control, formState } = useForm({
    defaultValues: state?.formDetails?.mentee
  });

  const classes = useStyles();

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleComplete();
      setStep(step + 1);
    }
  }, [formState, state]);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { mentee: partialData } } as any);
    setStep(step - 1);
  };

  const handleComplete = (): void => {
    api.post('/surveys/response', {
      type: UserType.Mentee,
      client: client.name,
      clientId: client.id,
      surveyId: client.menteesId,
      email: state.formDetails.mentee.email,
      formResponse: state.formDetails.mentee
    });
  };

  const onSubmit = (data: any) => {
    actions.updateAction({
      formDetails: {
        mentee: {
          ...data,
          clientId: clientId,
          clientName: client?.name
        }
      }
    } as any);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid style={{ justifyContent: 'center', alignItems: 'center' }} item>
          <div className={classes.formLabel} style={{ width: '100%' }}>
            <Typography variant="h5">Terms and Conditions</Typography>
            <li style={{ listStyleType: 'none' }}>
              <ul className={classes.formLabel} style={{ padding: '0' }}>
                Please take a minute to read
              </ul>

              <ul className={classes.formLabel}>
                1. You get up to 2 (30 min) sessions per month OR Up to 1 hour session per month minimum (if 1 hour is
                agreed upon by mentor and mentee).
              </ul>

              <ul className={classes.formLabel}>
                2. Sessions cancelled by mentee may not be rescheduled especially if last minute cancelation -
                additional sessions can be booked for subsequent months.
              </ul>

              <ul className={classes.formLabel}>
                3. If a mentor cancels a session or multiple, we will work to reschedule those sessions with your
                mentor. If mentor is unavailable, you’ll be matched with a different suitable mentor.
              </ul>

              <ul className={classes.formLabel}>
                4. All communications with mentor are confidential, but Locelle may reach out to ask for testimonials to
                be shared for marketing purposes. Your consent will be important and you will be asked prior.
              </ul>

              <ul className={classes.formLabel}>
                5. If you are not satisfied with your mentor match, please let your Client Success team member know and
                we will re-match you :-)
              </ul>

              <ul className={classes.formLabel}>
                6. Locelle, the mentors and your employer is not liable or responsible for any decisions you make as a
                result of participating in the program
              </ul>
            </li>
          </div>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              As a mentee: I consent to confidentiality and non disclosure regarding sessions with mentors.
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="agree"
                    control={<Radio color="primary" />}
                    label="I agree"
                    labelPlacement="end"
                  />
                </RadioGroup>
              )}
              name="confidentiality"
              control={control}
              rules={{ required: true }}
            />
            {formState.errors?.confidentiality?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section5;
