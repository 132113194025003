import React from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from '../styles';
import { Button, Link, Typography } from '@mui/material';
import { IFormProps } from '../newMenteeSurvey/NewMenteeSurvey';

const CommonSubmissionComplete: React.FC<IFormProps> = ({ hasAuthId }) => {
  const classes = useStyles();

  console.log('hasAuthId', hasAuthId);
  const portalUrl =
    process.env.NODE_ENV === 'development' || window.location.href.split('.').includes('stg')
      ? 'https://portal.stg.locelle.com'
      : 'https://portal.locelle.com';

  return (
    <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
      <Grid className={classes.headerBlock} item xs={12}>
        <Typography sx={{ my: 3 }} variant="h4" align="center" component="h1" gutterBottom>
          Please create an account to get access to the Portal.
        </Typography>
        {!hasAuthId && (
          <Typography variant="h6" align="center" component="h2">
            <Button
              variant="contained"
              sx={{
                bgcolor: '#E91D63',
                m: 2,
                width: '300px',
                height: '100px',
                ':hover': {
                  bgcolor: '#E8437A'
                }
              }}
              href={portalUrl}
              target="_blank"
            >
              <Typography variant="h6">Create Your Account</Typography>
            </Button>
            <Typography sx={{ my: 2 }}>
              <Link
                href="https://www.loom.com/share/cce1a40733f14f0caab82fcab38ecc95"
                target="_blank"
                underline="hover"
                fontSize="medium"
              >
                Watch this short video on how to do that
              </Link>
            </Typography>
          </Typography>
        )}
        {hasAuthId && (
          <Typography variant="h6" align="center" component="h2" gutterBottom>
            Looks like you have already created an account on our Portal
            <Button
              variant="contained"
              sx={{
                bgcolor: '#E91D63',
                m: 2,
                ':hover': {
                  bgcolor: '#E8437A'
                }
              }}
              href={portalUrl}
              target="_blank"
            >
              Go to Locelle Portal
            </Button>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CommonSubmissionComplete;
