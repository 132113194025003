import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { updateSession } from '../../redux/actions/sessions';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  currentSession: { id: string; date: string };
  refreshSessionList: () => void;
}

const CancelSessionDialog = (props: Props) => {
  const dispatch = useDispatch();

  const agreeCancelSession = () => {
    dispatch(updateSession({id : props.currentSession.id, status : 'CANCELLED' , origin: 'PORTAL'}));
    props.refreshSessionList();
    props.handleClose();
  }

  return (
    <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel the session?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to cancel your session? We may not be able to reschedule. It is hard to find time with mentors outside of scheduled times, so please if you have to cancel and would like to reschedule, please take the following action:
          Email us at {<a href="mailto:team@locelle.com?subject=Reschedule Session" style={{ textDecoration: 'none', color: '#241a1a'}}>team@locelle.com</a>} with your availability over the next 2-3 days.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Keep</Button>
          <Button onClick={agreeCancelSession} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default CancelSessionDialog
