import React from 'react';
import {
  FormHelperText,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import '../../../../../css/forms.css';
import { IFormProps } from './section.interface';

const Section3: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentee
  });

  const watchTopics = watch('topics');
  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { mentee: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentee: data } } as any);
    setStep(step + 1);
  };

  const selectPersonalityValidation = () =>
    getValues('personality').length === 3 ? true : 'Please select three options';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>What is your job title? *</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="ex. HR Manager, Technical Analyst etc."
                  fullWidth
                  variant="outlined"
                />
              )}
              name="title"
              control={control}
              rules={{ required: true }}
            />
            {errors?.title?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>Years of experience in the workplace *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup className={classes.horizontalRadioGroup} row {...field}>
                  {['Less than 5', '5-10', '10-15', '20+'].map((experience, index) => (
                    <FormControlLabel
                      key={index}
                      value={experience}
                      control={<Radio color="primary" />}
                      label={experience}
                      labelPlacement="bottom"
                    />
                  ))}
                </RadioGroup>
              )}
              name="experience"
              control={control}
              rules={{ required: true }}
            />
            {errors?.experience?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              Which of the following areas of business do you function in? *
            </FormLabel>
            <FormGroup row>
              {[
                'Accounting/Finance',
                'Admin/Operations',
                'Community/Partnerships',
                'Engineering/IT/Dev',
                'HR/Recruitment',
                'Marketing/Communications',
                'People/Culture',
                'Sales/Growth',
                'Strategy/Executive Leadership',
                'Other'
              ].map((topic, index) => (
                <Grid className={classes.checkboxContainer} item xs={12} key={index}>
                  <FormLabel className={classes.checkboxLabel} key={topic}>
                    <input
                      className={classes.checkbox}
                      {...register('topics', { required: true })}
                      type="checkbox"
                      value={topic}
                      name="topics"
                    />
                    {topic}
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {(errors.topics as any)?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchTopics?.includes('Other') && (
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField {...field} placeholder="Other *" required fullWidth variant="outlined" />
                )}
                name="otherTopic"
                control={control}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              Pick 3 of the following which best describes your personality *
            </FormLabel>
            <FormGroup row>
              {[
                'Extroverted',
                'Introverted',
                'Motivational',
                'Encouraging',
                'Calm',
                'Direct',
                'Compassionate',
                'Outgoing and upbeat'
              ].map((personality, index) => (
                <Grid className={classes.checkboxContainer} item xs={12} sm={6} key={index}>
                  <FormLabel className={classes.checkboxLabel} key={personality}>
                    <input
                      className={classes.checkbox}
                      {...register('personality', { required: true, validate: selectPersonalityValidation })}
                      type="checkbox"
                      value={personality}
                      name="personality"
                    />
                    {personality}
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {(errors.personality as any)?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
            {(errors.personality as any)?.type === 'validate' && (
              <FormHelperText className={classes.errorText}>{(errors.personality as any)?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section3;
