import { Button, Card, CardContent, CardHeader, CardMedia, CircularProgress, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Session } from '../../../models/session';
import { sendSurveysResponse } from '../../../redux/actions/surveysResponse';
import { updateSession } from '../../../redux/actions/sessions';
import CheckIcon from '@mui/icons-material/Check';
import Logo from '../../../assets/logo.png';

type Props = {
  clientId: string;
  currentSession: Session;
  handleClose: () => void;
}

const useStyles = makeStyles({
  formLabel: {
    marginTop: '25px',
    marginBottom: '15px',
    fontWeight: 'bold',
    color: 'black',
  },
  checkbox: { color: '#000000', fontSize: '18px', fontStyle: 'normal', fontWeight: 300 },
  errorText: {
    color: 'red'
  }
});

function FinalFeedbackForm(props: Props) {

  // const { state, actions } = useStateMachine({
  //   updateAction
  // });

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const classes = useStyles();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();

  const { menteeObject } = useParams<{ clientId: string, menteeObject: string }>();
  const menteeData = JSON.parse(atob(menteeObject));

  const { register, handleSubmit, getValues, watch, control, formState: { errors } } = useForm();

  const watchThingsImproved = watch('thingsImproved');
  const watchImprovement = watch('improvement');
  const watchOpportunity = watch('opportunity');
  const watchAssistance = watch('assistance');
  const watchLeadership = watch('leadership');
  const watchNextCohort = watch('nextCohort');



  const submitForm = async (data: any) => {
    const { menteeemail, surveyid, clientId, name, description, id, status } = menteeData;
    try {
      setLoading(true);
      setTimeout(() => {

        data.email = menteeemail;
        data.match = description;
        data.name = name;
        const feedback = {
          type: "final",
          clientId: clientId,
          surveyId: surveyid,
          email: menteeemail,
          formResponse: data
        }
        dispatch(sendSurveysResponse(feedback))
        const statusUpdate = status === 'CONFIRMED'? 'FEEDBACK': 'COMPLETED';
        dispatch(updateSession({ id: id, status: statusUpdate, completementee: true }));
        setLoading(false);
        setSuccess(true);
        // props.handleClose();
      }, 3000);

      // setTimeout(() => {props.handleClose();}, 5000);

    } catch (e) {
      alert(e);
    }
  }
  return (
    <div style={{ backgroundColor: '#f0ebf8' }}>
      {success ? (
        <Card elevation={0}>
          <CardContent>
            <img src={Logo} style={{ display: 'block', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '100px' }} />
            <Typography align='center'>Thank you for submiting! You can now close this window</Typography>
          </CardContent>

        </Card>) :
        (<>
          <Card style={{ margin: '0 auto', width: '700px', borderTop: '10px solid #673ab7', borderRadius: '10px' }} elevation={2} >
            <img src={Logo} style={{ display: 'block', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '100px' }} />
            <Typography variant="h6" align='center'>{`${menteeData?.company} Final Session -  ${menteeData?.name}`}</Typography>
            <CardHeader
              subheader="Congratulations on completing your mentorship sessions! Your feedback is appreciated to ensure you had a positive experience and enjoyed meeting with your mentor, this also helps us make improvements where needed! "
            />
          </Card>
          <br />
          <form style={{ alignItems: 'center' }} onSubmit={handleSubmit(submitForm)} >
            <Card style={{ margin: '0 auto', width: '700px', borderRadius: '15px 15px 0 0' }} elevation={2} >
              <CardContent>
                <Grid container
                  spacing={0}
                  direction="column"
                >
                  <Grid item xs={6} >
                    <FormControl >
                      <FormLabel className={classes.formLabel} >What skills/areas of improvement have your strengthened throughout your mentorship? *</FormLabel>
                      <FormGroup style={{ marginTop: '25px' }}>
                        {[
                          'Increased productivity',
                          'Identifying strengths',
                          'Increased confidence',
                          'Advancing in my career',
                          'Learning to beat imposter syndrome',
                          'Learning to negotiate for what I want',
                          'Balancing work and life',
                          'Building my network & influence',
                          'Other'].map((improvement, index) =>
                            <Grid item xs={12} key={index}>
                              <FormLabel key={improvement} className={classes.checkbox}>
                                <input {...register('improvement', { required: true })} type='checkbox' value={improvement} name='improvement' style={{ marginRight: '15px' }} />
                                <span style={{ color: 'black' }}>{improvement}</span>
                              </FormLabel>
                            </Grid>
                          )}
                      </FormGroup>
                      {(errors.improvement as any)?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                    {watchImprovement && watchImprovement.includes('Other') &&
                      <Grid item xs={12}>
                        <br />
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherImprovement"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>How has the opportunity for outside mentorship helped you? *</FormLabel>

                      <FormGroup >
                        {['I feel more supported at work',
                          'I have grown personally',
                          'I have grown professionally',
                          'Promotion',
                          'Role change',
                          'Other'].map((opportunity, index) => (
                            // <FormControlLabel key={index} value={opportunity} control={<Radio color='primary' />} label={opportunity} />
                            <Grid item xs={12} key={index}>
                              <FormLabel key={opportunity} className={classes.checkbox}>
                                <input {...register('opportunity', { required: true })} type='checkbox' value={opportunity} name='opportunity' style={{ marginRight: '15px' }} />
                                <span style={{ color: 'black' }}>{opportunity}</span>
                              </FormLabel>
                            </Grid>

                          ))}
                      </FormGroup>

                      {errors?.opportunity?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                    {watchOpportunity && watchOpportunity.includes('Other') &&
                      <Grid item xs={12}>
                        <br />
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherOpportunity"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>In what ways have things improved since the mentorship? *</FormLabel>

                      <FormGroup>
                        {['Makes me want to remain at my company',
                          'Attraction of talent',
                          'Better communications with teams',
                          'D&I initiatives have developed',
                          'Connecting well with leadership',
                          'Workplace culture',
                          'Other'].map((thingsImproved, index) => (
                            <Grid item xs={12} key={index}>
                              <FormLabel key={thingsImproved} className={classes.checkbox}>
                                <input {...register('thingsImproved', { required: true })} type='checkbox' value={thingsImproved} name='thingsImproved' style={{ marginRight: '15px' }} />
                                <span style={{ color: 'black' }}>{thingsImproved}</span>
                              </FormLabel>
                            </Grid>
                          ))}
                      </FormGroup>

                      {errors?.improved?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                    {watchThingsImproved && watchThingsImproved.includes('Other') &&
                      <Grid item xs={12}>
                        <br />
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherThingsImproved"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>If you are not already in a leadership role, would you prefer to step into leadership?   *</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            {['Yes', 'No', 'Not yet', 'Other:'].map((leadership, index) => (
                              <FormControlLabel key={index} value={leadership} control={<Radio color='primary' />} label={leadership} />
                            ))}
                          </RadioGroup>
                        )}
                        name='leadership'
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors?.leadership?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                    {watchLeadership && watchLeadership.includes('Other') &&
                      <Grid item xs={12}>
                        <br />
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherLeadership"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item xs={12} >
                    <FormControl >
                      <FormLabel className={classes.formLabel} >In what areas can Locelle/BestHuman improve on? *</FormLabel>
                      <FormGroup style={{ marginTop: '25px' }}>
                        {['Mentor matching',
                          'Video platform',
                          'Portal',
                          'Providing resources',
                          'Scheduling',
                          'Roundtables',
                          'Other'].map((assistance, index) =>
                            <Grid item xs={12} key={index}>
                              <FormLabel key={assistance} className={classes.checkbox}>
                                <input {...register('assistance', { required: true })} type='checkbox' value={assistance} name='assistance' style={{ marginRight: '15px' }} />
                                <span style={{ color: 'black' }}>{assistance}</span>
                              </FormLabel>
                            </Grid>
                          )}
                      </FormGroup>
                      {(errors.assistance as any)?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }} >*Required</FormHelperText>}
                    </FormControl>
                    {watchAssistance && watchAssistance.includes('Other') &&
                      <Grid item xs={12}>
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherAssistance"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>If you had a positive experience with your mentor, share a testimonial that we can share with them to celebrate and provide a token of appreciation for their commitment to helping women in the workplace.</FormLabel>
                      <Controller
                        render={({ field }) =>
                          <TextField {...field} placeholder='Please write testimonial here *' required fullWidth variant='outlined' />
                        }
                        name="otherTestimonial"
                        control={control}
                      />
                      {/* {errors?.ethnic?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>} */}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel className={classes.formLabel}>How would you like to proceed in the next cohort? *</FormLabel>
                      <Controller
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            {[
                              'Continue, same mentor',
                              'Continue, new mentor',
                              'Take a break from program',
                              'Other'].map((nextCohort, index) => (
                                <FormControlLabel key={index} value={nextCohort} control={<Radio color='primary' />} label={nextCohort} />
                              ))}
                          </RadioGroup>
                        )}
                        name='nextCohort'
                        control={control}
                        rules={{ required: true }}
                      />
                      {errors?.nextCohort?.type === 'required' && <FormHelperText style={{ color: 'red', fontWeight: 'bold' }}>*Required</FormHelperText>}
                    </FormControl>
                    {watchNextCohort && watchNextCohort.includes('Other') &&
                      <Grid item xs={12}>
                        <Controller
                          render={({ field }) =>
                            <TextField {...field} placeholder='Other *' required fullWidth variant='outlined' />
                          }
                          name="otherNextCohort"
                          control={control}
                        />
                      </Grid>
                    }
                  </Grid>
                </Grid>
                {false ? (<Fab size='small' style={{ backgroundColor: '#91cd91', position: 'absolute', bottom: 15, right: '15px' }}><CheckIcon fontSize='small' /></Fab>) : (<Button style={{ float: 'right', bottom: '5px', backgroundColor: '#673ab7' }} type="submit" variant='contained' disabled={loading}>Done</Button>)}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'green',
                      position: 'absolute',
                      bottom: '20px',
                      right: '50px'
                    }} />
                )}
              </CardContent>
            </Card>
          </form>
        </>)
      }
    </div>
  )
}

export default FinalFeedbackForm;
