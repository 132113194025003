import api from '../../services/api';
import { Card, CardContent, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';
import { useEffect, useState } from 'react';

const LeadershipQuotes = () => {
  const [quote, setQuote] = useState('loading quote...');
  const [author, setAuthor] = useState('');

  useEffect(() => {
    api.get('/quotes/quote-of-the-day').then(({ data: { quote, author } }) => {
      setQuote(quote);
      setAuthor(author);
    });
  }, []);

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: 'auto',
          maxWidth: '80%',
          height: 'auto',
          backgroundColor: Colors.BackgroundMainLighter,
          boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
          borderRadius: 7,
          position: 'relative',
          '&:hover': {
            boxShadow: '0 0 10px 5px rgba(223, 245, 39, 0.19)'
          }
        }}
      >
        <CardContent sx={{ position: 'relative', padding: 2 }}>
          <Typography
            sx={{
              position: 'absolute',
              top: -1,
              left: 17,
              fontSize: '2.8rem',
              color: 'lightsalmon'
            }}
          >
            ❝
          </Typography>
          <Typography
            component="div"
            sx={{
              color: Colors.TextElevated,
              fontFamily: 'Arial, sans-serif',
              fontSize: '1.4rem',
              ml: '3rem',
              mt: 1,
              mr: 4,
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap'
            }}
          >
            {quote}
          </Typography>
          <Typography sx={{ color: 'grey', textAlign: 'end', mb: 2, mt: 1, mr: 5 }}>― {author}</Typography>
          <Typography
            sx={{
              position: 'absolute',
              bottom: -12,
              right: 15,
              fontSize: '2.8rem',
              color: 'lightsalmon'
            }}
          >
            ❞
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default LeadershipQuotes;