import React, { ReactElement, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import VideoMediaCarousel from './VideoMediaCarousel';
import ToolsCarousel from './ToolsCarousel';
import { Colors } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import { findUserProfile, getUserProfile } from '../../redux/actions/user';
import { IUserProfile } from '../../redux/types';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      font: 'Roboto',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: 700,
      marginTop: '10px',
      marginLeft: '20px',
      marginBottom: '-10px',
      color: Colors.TextElevated
    }
  })
);

export default function LearningCenter({}: Props): ReactElement {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const user = useSelector(getUserProfile);
  const classes = useStyles();

  useEffect(() => {
    if (userId) {
      dispatch(findUserProfile(userId));
    }
  }, [userId]);

  return (
    <div>
      <div className={classes.section}>Tools</div>
      <ToolsCarousel user={user} />
      <div className={classes.section}>Learning Library</div>
      <VideoMediaCarousel />
    </div>
  );
}
