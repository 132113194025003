import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import CommonSubmissionComplete from "../mutualSections/CommonSubmissionComplete";

export default [
  {
    step: 1,
    component: Section1
  },
  {
    step: 2,
    component: Section2
  },
  {
    step: 3,
    component: Section3
  },
  
  {
    step: 4,
    component: Section4
  },

  {
    step: 5,
    component: CommonSubmissionComplete
  },
];
