import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Colors } from '../../enums/enums';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import {Box} from "@mui/material";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  videoId: string;
}

export default function VideoDialog({ isOpen, handleClose, videoId }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <AppBar sx={{ position: 'relative', backgroundColor: Colors.SelectedDrawerBackground }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton edge="end" color="success" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ width: 'auto' }}>
        <iframe
          width="650"
          height="480"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}