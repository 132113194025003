import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
    FormControl,
    DialogTitle,
    Dialog,
    Button,
    Typography,
    Box,
    Stack,
    Divider,
    RadioGroup,
    Radio
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, FormHelperText } from '@material-ui/core';
import { Colors } from '../../../enums/enums';

interface Props {
    isOpen: boolean;
    userId: string;
    handleClose: () => void;
}

const TermsAndConditionsAgreement = (props: Props) => {
    const history = useHistory();

    const submitAgreement = (data) => {
        try {
            const surveyData = { userId: props.userId, formResponse: data };
            api
                .post('/surveys/terms-and-conditions-agreement', surveyData)
                .then(({ data }) => {
                    toast.success('Thank you for signing Terms and Conditions Agreement');
                    props.handleClose();
                    history.push(`/tasks`);
                })
                .catch(() => toast.error('Error signing Terms and Conditions Agreement'));
        } catch (e) {
            toast.error(`Error signing Terms and Conditions Agreement ${e?.data?.message}`);
        }
    };

    const { handleSubmit, control, formState } = useForm({
        defaultValues: {
            agreeToTermsAndConditions: null
        }
    });

    return (
        <Dialog maxWidth="lg" open={props.isOpen} onClose={props.handleClose}>
            <DialogTitle
                sx={{
                    position: 'sticky',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    backgroundColor: Colors.BackgroundDrawer
                }}
            >
                <Stack direction="row" spacing={2} justifyContent="space-between" textAlign="center">
                    <Typography sx={{ fontSize: '1.6rem', color: Colors.TextElevated }}>
                        TERMS AND CONDITIONS
                        <Typography>Please take a minute to read and sign.</Typography>
                    </Typography>

                    <Button
                        startIcon={<HomeIcon />}
                        sx={{
                            backgroundColor: '#9f9595',
                            color: '#FFFFFF',
                            width: 190,
                            height: 35,
                            '&:hover': {
                                backgroundColor: '#7c7272'
                            }
                        }}
                        onClick={() => {
                            history.push(`/tasks`);
                            props.handleClose();
                        }}
                    >
                        Return to Portal
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ height: '900px', backgroundColor: Colors.BackgroundMain }}>
                <li style={{ listStyleType: 'none', marginLeft: -30 }}>
                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>1. Canceled or Missed Sessions:</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            <b>{'>'} 1:1 Sessions:</b> Please allow at least 48 hours before canceling a session to be rescheduled.
                            Sessions canceled within 48 hours of the upcoming session may not be rescheduled. If a mentor or coach
                            cancels, those sessions will be rescheduled.
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            <b>{'>'} Group Sessions:</b> Group sessions can’t be canceled unless the mentor or coach is unavailable in
                            which case they will be rescheduled.
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            <b>{'>'} No shows:</b> These sessions will not be rescheduled. If more than 2 no shows, the cohort will
                            end.
                        </Typography>
                    </ul>

                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>2. Confidentiality:</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            All communications with your match are confidential, but Locelle may reach out to ask for testimonials to
                            be shared for marketing purposes. Your consent will be important and you will be asked prior.
                        </Typography>
                    </ul>

                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>3. Satisfaction:</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            {'>'} 1:1 If you are not satisfied with your match, please let your Client Success team member know.
                            <br />
                            {'>'} Group: If you are not satisfied with the group, we will cancel your plan and refund for all
                            remaining sessions.
                        </Typography>
                    </ul>

                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>4. Liability</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }}>
                            Locelle/BestHuman, the mentors, coaches and/or your employer is not liable or responsible for any
                            decisions you make as a result of participating in the program.
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            As a participant, you consent to confidentiality and non disclosure regarding sessions facilitated by
                            Locelle/BestHuman and all its programs.
                        </Typography>
                    </ul>
                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>5. Refund or cancellation of the program(s)</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            After onboarding, if you have not yet been matched, or started a program, you may qualify for a refund
                            minus $100 admin fee.
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            If you have been matched, you may qualify for a refund of $200.{' '}
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            If you cancel within 5 business days of your upcoming session, you will be refunded for sessions after
                            notice period.
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            There will be no refunds for sessions attended.
                        </Typography>
                    </ul>
                    <ul>
                        <Typography component="p" sx={{ color: '#4b4848', fontSize: '1.1rem' }} gutterBottom>
                            <b>6. Privacy Policy</b>
                        </Typography>
                        <Typography sx={{ ml: 2 }} gutterBottom>
                            If you choose to use our Service, then you agree to the collection and use of information in relation to
                            this policy. The Personal Information that we collect is used for providing and improving the Service. We
                            will not use or share your information with anyone except as described in this Privacy Policy.
                        </Typography>
                        <ul>
                            <Typography>
                                <b>&#x2022; Service Providers</b>
                            </Typography>
                            <Typography sx={{ ml: 2 }} gutterBottom>
                                We may employ third-party companies and individuals due to the following reasons:
                            </Typography>
                            <Typography gutterBottom>
                                <ul>&#x2022; To facilitate our Service;</ul>
                                <ul>&#x2022; To provide the Service on our behalf;</ul>
                                <ul>&#x2022; To perform Service-related services; or</ul>
                                <ul>&#x2022; To assist us in analyzing how our Service is used.</ul>
                            </Typography>
                            <Typography sx={{ ml: 2 }} gutterBottom>
                                We want to inform users of this Service that these third parties have access to your Personal
                                Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
                                obligated not to disclose or use the information for any other purpose.
                            </Typography>
                        </ul>

                        <ul>
                            <Typography>
                                <b>&#x2022; Security</b>
                            </Typography>
                            <Typography sx={{ ml: 2 }} gutterBottom>
                                We value your trust in providing us your Personal Information, thus we are striving to use commercially
                                acceptable means of protecting it. But remember that no method of transmission over the internet, or
                                method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                            </Typography>
                        </ul>

                        <ul>
                            <Typography>
                                <b>&#x2022; Links to Other Sites</b>
                            </Typography>
                            <Typography sx={{ ml: 2 }} gutterBottom>
                                This Service may contain links to other sites. If you click on a third-party link, you will be directed
                                to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you
                                to review the Privacy Policy of these websites. We have no control over and assume no responsibility for
                                the content, privacy policies, or practices of any third-party sites or services.
                            </Typography>
                        </ul>
                    </ul>
                </li>
                <Divider />
                <Box sx={{ ml: 4, my: 2 }}>
                    <form onSubmit={handleSubmit(submitAgreement)}>
                        <FormControl>
                            <Controller
                                render={({ field }) => (
                                    <RadioGroup row {...field}>
                                        <FormControlLabel
                                            value="agree"
                                            control={<Radio color="success" />}
                                            label="I agree"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                )}
                                name="agreeToTermsAndConditions"
                                control={control}
                                rules={{ required: true }}
                            />
                            {formState.errors?.agreeToTermsAndConditions?.type === 'required' && (
                                <FormHelperText color="red">Please click 'I agree' to sign the Terms and Conditions Agreement and then press the Submit button</FormHelperText>
                            )}
                        </FormControl>
                        <Button variant="contained" color="success" sx={{ float: 'right', mr: 3, mt: 1 }} type="submit">
                            Submit
                        </Button>
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TermsAndConditionsAgreement;