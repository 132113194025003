import React from 'react';
import {
  FormHelperText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import '../../../../../css/forms.css';
import { IFormProps } from './section.interface';
const Section4: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    register,
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentee
  });
  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { mentee: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentee: data } } as any);
    setStep(step + 1);
  };

  const selectThree = () => (getValues('personality').length === 3 ? true : 'Please select three options');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Our community is diverse and we are proud of it. We appreciate if you can share your ethnic origin. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    'Asian or South Asian',
                    'Black or African American',
                    'Hispanic or Latinx',
                    'Indigenous Canadian or American',
                    'White',
                    'Prefer not to answer'
                  ].map((ethnic, index) => (
                    <FormControlLabel key={index} value={ethnic} control={<Radio color="primary" />} label={ethnic} />
                  ))}
                </RadioGroup>
              )}
              name="ethnic"
              control={control}
              rules={{ required: true }}
            />
            {errors?.ethnic?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              We are intentional about building a diverse community. We appreciate if you would be willing to share how
              you identify yourself. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['She/Her', 'He/Him', 'They/Them', 'Ze/Hir', 'Xe/Xem', 'Prefer not to answer'].map(
                    (pronoun, index) => (
                      <FormControlLabel
                        key={index}
                        value={pronoun}
                        control={<Radio color="primary" />}
                        label={pronoun}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="pronoum"
              control={control}
              rules={{ required: true }}
            />
            {errors?.pronoum?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section4;
