import { Button, CircularProgress, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentSurveys } from '../../../redux/actions/surveys';
import { Session } from '../../../models/session';
import { sendFinalSurveysResponse } from '../../../redux/actions/surveysResponse';
import { useAuth0 } from '@auth0/auth0-react';
import { getInternalUser } from '../../../redux/actions/user';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  clientId: string;
  currentSession: Session;
  handleClose: () => void;
}

const useStyles = makeStyles({
  formLabel: {
    marginTop: '25px',
    marginBottom:'15px',
    fontWeight: 'bold',
    color: '#4c2525',
  },
  checkbox: {color: '#000000', fontSize: '18px',fontStyle: 'normal', fontWeight: 300 },
  errorText: {
    color: 'red'
  }
});

function FinalFeedbackFormMentor(props: Props) {

  
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, getValues, watch, control, formState: { errors }} = useForm();

  
  const watchAvailability = watch('availability');

  useEffect(() => {
    dispatch(fetchCurrentSurveys());
    
  }, [dispatch]);

const { user } = useAuth0();
const internalUser = useSelector(getInternalUser);

const submitForm = async(data: any) => {
  
  try{
    setLoading(true);
    setTimeout(()=> {
    data.email = user.nickname;
    data.match = props.currentSession.description;
    data.name = internalUser?.name;
    const feedback = {
      type: "final",
      clientId: props.currentSession.clientId,
      surveyId: props.currentSession.feedback_form,
      sessionId: props.currentSession.id,
      email: user.email,
      formResponse: data
    }
    const statusUpdate = props.currentSession.status === 'CONFIRMED'? 'FEEDBACK': 'COMPLETED';
    const sessionUpdate = {sessionId : props.currentSession.id, sessionStatus : statusUpdate, completementor: true }

    dispatch(sendFinalSurveysResponse({...feedback, ...sessionUpdate}));
    // dispatch(updateSession({id : props.currentSession.id, status : statusUpdate, completementor: true }));
    setLoading(false);
    setSuccess(true);
    
    }, 3000);
    
    setTimeout(() => {props.handleClose();}, 5000);
    
  } catch(e){
    alert(e);
  }
}
  return (
    <form style={{ alignItems:'center'}} onSubmit={handleSubmit(submitForm)} >
     
      <Grid container
            spacing={0}
            direction="column"
            // alignItems="center"
            // justifyContent="center"
            // style={{ minHeight: '100vh' }}
            >
       
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>Have you grown personally and professionally as a result of mentorship program? *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['Yes',
                    'No'].map((grown, index) => (
                    <FormControlLabel key={index} value={grown} control={<Radio color='primary' />} label={grown} />
                  ))}
                </RadioGroup>
              )}
              name='grown'
              control={control}
              rules={ { required: true } }
            />
            {errors?.grown?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
        </Grid>        
        
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>Do you have any feedback or comments for your mentee? This can be kept confidential*</FormLabel>
              <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined'/>
                    }
                    name="menteeFeedback"
                    control={control}
                  />
            {errors?.menteeFeedback?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid> 
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What is the one thing that would make you happier about the program? Things we can improve upon.*</FormLabel>
              <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined'/>
                    }
                    name="locelleImprovement"
                    control={control}
                  />
            {errors?.locelleImprovement?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What is the one thing we are doing well?*</FormLabel>
              <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined'/>
                    }
                    name="locelleWell"
                    control={control}
                  />
            {errors?.locelleWell?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What else can Locelle do to support you?*</FormLabel>
              <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Your answer *' required fullWidth variant='outlined'/>
                    }
                    name="support"
                    control={control}
                  />
            {errors?.support?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
          </FormControl>
        </Grid>

          <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>What is your availability moving forward? 1 hour per month? *</FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {[
                    'Can commit to 1 year',
                    'Can commit to 6 months',
                    'Can commit to 3 months',
                    'Pause mentorship for now',
                    'Other'].map((availability, index) => (
                    <FormControlLabel key={index} value={availability} control={<Radio color='primary' />} label={availability} />
                  ))}
                </RadioGroup>
              )}
              name='availability' 
              control={control}
              rules={ { required: true } }
            />
            {errors?.availability?.type === 'required' && <FormHelperText style={{color: 'red', fontWeight: 'bold'}}>*Required</FormHelperText>}
          </FormControl>
          </Grid>
          { watchAvailability && watchAvailability.includes('Other') &&
                <Grid item xs={12}>
                  <br/>
                  <Controller
                    render={({ field }) => 
                      <TextField {...field} placeholder='Other: *' required fullWidth variant='outlined'/>
                    }
                    name="otherAvailability"
                    control={control}
                  />
                </Grid>
          }

      </Grid>
      {success ? (<Fab size='small' style={{backgroundColor: '#91cd91', position: 'absolute', bottom: 15, right: '15px'}}><CheckIcon fontSize='small' /></Fab>):(<Button style={{float: 'right'}} type="submit" variant='contained' disabled={loading}>Done</Button>)}
      {loading && (
          <CircularProgress 
          size={24}
          sx={{
            color: 'green',
            position: 'absolute',
            bottom: '20px',
            right: '50px'
          }}/>
      )}
    </form>
  )
}

export default FinalFeedbackFormMentor;