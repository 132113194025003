import React from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Grid
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';

interface IFormProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentor
  });
  const classes = useStyles();

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentor: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Email address *</FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your email" fullWidth variant="outlined" />}
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Please enter a valid email' }
              }}
            />
            {errors?.email?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
            {errors?.email?.type === 'pattern' && (
              <FormHelperText className={classes.errorText}>{errors.email.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Enter Your LinkedIn Profile *</FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="i.e. https://www.linkedin.com/in/your-profile/"
                  helperText="Please copy and paste the entire link to your profile"
                  fullWidth
                  variant="outlined"
                />
              )}
              name="linkedin"
              control={control}
              rules={{ required: true }}
            />
            {errors?.linkedin?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Full name *</FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your answer" fullWidth variant="outlined" />}
              name="name"
              control={control}
              rules={{ required: true }}
            />
            {errors?.name?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>Title *</FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your answer" fullWidth variant="outlined" />}
              name="title"
              control={control}
              rules={{ required: true }}
            />
            {errors?.title?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your age? (we are collecting this data to best tailor the program) *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup {...field}>
                  {['18-29', '30-39', '40-49', '50+', 'Prefer not to say'].map((age, index) => (
                    <FormControlLabel key={index} value={age} control={<Radio color="primary" />} label={age} />
                  ))}
                </RadioGroup>
              )}
              name="age"
              control={control}
              rules={{ required: true }}
            />
            {errors?.age?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              Where are you located? (we are collecting this data to account for different time zones) *
            </FormLabel>
            <Controller
              render={({ field }) => <TextField {...field} placeholder="Your answer" fullWidth variant="outlined" />}
              name="location"
              control={control}
              rules={{ required: true }}
            />
            {errors?.location?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;
