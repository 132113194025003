import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Session } from '../../../models/session';
import FinalFeedbackForm from './FinalFeedbackForm';
import FinalFeedbackFormMentor from './FinalFeedbackFormMentor';
import { UserType } from '../../../enums/enums';

interface ILocelleUser {
  id: string;
  type: UserType;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  currentSession: Session;
  refreshSessionList: () => void;
  locelleUser: ILocelleUser;
}

const FinalSessionFeedback = (props: Props) => {
  const sessionUser = localStorage.getItem('LOCELLE:USER_TYPE');

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      // aria-labelledby="alert-dialog-title"
      // aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Feedback {props?.currentSession?.stage}</DialogTitle>
      <DialogContent>
        {UserType.Mentor === sessionUser || UserType.Mentor === props?.locelleUser?.type ? (
          <FinalFeedbackFormMentor
            clientId={props?.currentSession?.clientId}
            currentSession={props?.currentSession}
            handleClose={props.handleClose}
          />
        ) : (
          <FinalFeedbackForm
            clientId={props?.currentSession?.clientId}
            currentSession={props?.currentSession}
            handleClose={props.handleClose}
          />
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default FinalSessionFeedback;
