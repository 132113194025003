import React, { useState, useEffect } from 'react';
import ProgressComponent from '@material-ui/core/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import generate from '../../services/meeting/authMeeting';
import { useDispatch, useSelector } from 'react-redux';
import { findSessionLeaderByMeetingId, getSessionLeader } from '../../redux/actions/user';

function PrevMeetingRoom() {
  const { meetingId } = useParams<{ meetingId: string }>();

  const [loading, setLoading] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [roomNameValue, setRoomNameValue] = useState('');
  const [jwt, setJwt] = useState('');

  const { user } = useAuth0();
  const sessionLeader = useSelector(getSessionLeader);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Meeting Id', meetingId);
    dispatch(findSessionLeaderByMeetingId(meetingId));
  }, [meetingId]);

  useEffect(() => {
    if (sessionLeader) {
      const roomName = `vpaas-magic-cookie-26792429788c4f7db39e48dd7c73b6d4/${sessionLeader?.name}Room`;
      setRoomNameValue(roomName);

      const { id, email } = sessionLeader;
      const jwt = generate({ id, name: '', email });

      setJwt(jwt);
      setShowMeeting(true);
    }
  }, [sessionLeader]);

  const jitsiContainerStyle = {
    display: loading ? 'none' : 'block',
    width: '100%',
    height: '100vh'
  };

  function startConference() {
    try {
      const domain = '8x8.vc';
      const options = {
        roomName: roomNameValue,
        height: '100%',
        parentNode: document.getElementById('jitsi-container'),
        jwt,
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false
        },
        configOverwrite: {
          disableSimulcast: false
        }
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);

      api.addEventListener('videoConferenceJoined', () => {
        console.log('Local User Joined');
        setLoading(false);
        api.executeCommand('displayName', user?.nickname);
      });

      api.addEventListener('videoConferenceLeft', () => {
        setShowMeeting(false);
      });
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI && showMeeting) startConference();
    else {
      console.error('Jitsi Meet API script not loaded');
    }
  }, [showMeeting]);

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#eae6e6', position: 'absolute' }}>
      {loading && <ProgressComponent />}
      {showMeeting && <div id="jitsi-container" style={jitsiContainerStyle} />}

      {!showMeeting && <Typography>You're being redirected to the meeting in few seconds...</Typography>}
    </div>
  );
}

export default PrevMeetingRoom;
