import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import generate from '../../services/meeting/authMeeting';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { JITSI_APP_ID } from '../../constants/constants';

interface Props {
  mentor: { id: string; name: string; email: string };
  accessViaPortal: boolean;
  handleClose: () => void;
  hidePreMeetingOptions: () => void;
}

function JitsiMeetingRoom({ mentor, accessViaPortal, handleClose, hidePreMeetingOptions }: Props) {
  const [loading, setLoading] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);

  const { user } = useAuth0();

  const { sub, name, email } = user;

  const roomName = `${mentor?.name}Room`;
  const jwt = generate({ id: sub, name, email });

  return (
    <div style={{ height: '100%' }}>
      <JaaSMeeting
        appId={JITSI_APP_ID}
        roomName={roomName}
        jwt={jwt}
        getIFrameRef={(iframe) => {
          iframe.style.height = '100%';
        }}
        userInfo={{
          displayName: name,
          email: email
        }}
        configOverwrite={
          {
            // innerHeight: '100%',
            // disableThirdPartyRequests: true,
            // disableLocalVideoFlip: true,
            // backgroundAlpha: 0.5
          }
        }
        interfaceConfigOverwrite={
          {
            // VIDEO_LAYOUT_FIT: 'nocrop',
            // MOBILE_APP_PROMO: false,
            // TILE_VIEW_MAX_COLUMNS: 4
          }
        }
        onApiReady={() => {
          console.log('Local User Joined ' + user.name);
          hidePreMeetingOptions();
        }}
        onReadyToClose={() => {
          setShowMeeting(false);
          handleClose();
        }}
      />
    </div>
  );
}

export default JitsiMeetingRoom;
