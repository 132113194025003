import React, { useEffect } from 'react';
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Grid,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import api from '../../../../../services/api';
import { UserRoleType, UserType } from '../../../../../enums/enums';
import { Typography } from '@mui/material';
import { IFormProps } from '../NewMenteeSurvey';

interface IFormClientProps extends IFormProps {
  client: { id: string; code: string; name: string };
}

const Section2: React.FC<IFormClientProps> = ({ step, setStep, client, userId, userEmail, surveyId }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const { handleSubmit, getValues, control, formState } = useForm({
    defaultValues: state?.formDetails?.newMentee
  });

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      handleComplete();
      setStep(step + 1);
    }
  }, [formState, state]);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { newMentee: partialData } } as any);
    setStep(step - 1);
  };

  const handleComplete = (): void => {
    api.post('/surveys/onboarding/additional', {
      type: UserType.Mentee,
      role: UserRoleType.Mentee,
      client: client.name,
      clientId: client.id,
      email: userEmail,
      userId: userId,
      surveyId: surveyId,
      formResponse: state.formDetails.newMentee
    });
    actions.updateAction({
      formDetails: {
        newMentee: null
      }
    } as any);
  };

  const onSubmit = (data: any) => {
    console.log('FormDetails Mentee >>>>>', data);
    actions.updateAction({
      formDetails: {
        newMentee: {
          ...data,
          clientId: client.id,
          clientName: client?.name
        }
      }
    } as any);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid style={{ justifyContent: 'center', alignItems: 'center' }} item>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              <Typography variant="h5">Terms and Conditions</Typography>
              <Typography sx={{ my: 4 }}>
                <a href="https://locelle.com/terms-and-conditions-for-new-portal/" target="_blank">Read full terms and conditions</a>
              </Typography>
              As a participant, I consent to the terms and conditions and to the confidentiality and non disclosure
              regarding all sessions through Locelle.
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="agree"
                    control={<Radio color="primary" />}
                    label="I agree"
                    labelPlacement="end"
                  />
                </RadioGroup>
              )}
              name="confidentiality"
              control={control}
              rules={{ required: true }}
            />
            {formState.errors?.confidentiality?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="secondary" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section2;
