export enum UserType {
  Mentee = 'mentees',
  Mentor = 'mentors',
  All = 'all',
  Coaching = 'coaching'
}

export enum UserRoleType {
  Mentor = 'Mentor',
  Mentee = 'Mentee',
  Coach = 'Coach',
  Coachee = 'Coachee',
  BasicSubscription = 'Basic Subscription'
}
export enum SessionNotesTemplates {
  MeetAndGreet = 'MEET_AND_GREET_NOTES_TEMPLATE',
  RecurringSession = 'RECURRING_NOTES_TEMPLATE',
  FinalSession = 'FINAL_NOTES_TEMPLATE',
  OneOnOne = 'ONE_ON_ONE_NOTES_TEMPLATE'
}

export enum SessionStageType {
  MeetAndGreet = 'Meet And Greet',
  RecurringSession = 'Recurring Session',
  FinalSession = 'Final Session',
  Coaching = 'Coaching Session',
  Individual = 'Individual Session'
}

export enum SessionStatusType {
  Confirmed = 'CONFIRMED',
  Feedback = 'FEEDBACK',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum MenuTypes {
  Home = 'Home',
  Profile = 'Profile',
  Mentoring = 'Mentoring',
  Sessions = 'Sessions',
  Community = 'Community',
  Learning = 'Resources',
  Tasks = 'Tasks',
  FAQ = 'FAQ',
  LogOut = 'Log Out'
}

export enum NoteTypes {
  Mentoring = 'Mentoring',
  Coaching = 'Coaching'
}

export enum TaskType {
  FirstSessionAssessment = 'First Session Assessment',
  MidCohortGroupAssessment = 'Mid Cohort Group Assessment',
  FinalGroupAssessment = 'Final Group Assessment',
  PilotGroupCoachingAssessment = 'Pilot Group Coaching Assessment',
  OneOnOneFinalSurvey = 'One on One Final Survey'
}

export enum SurveyTargetType {
  All = 'all',
  Mentees = 'mentees',
  Mentors = 'mentors',
  Coaches = 'coaches',
  Coachees = 'coachees'
}

export enum SurveyType {
  OnboardingMentee = 'Onboarding Mentee',
  OnboardingMentor = 'Onboarding Mentor',
  MeetAndGreetFeedback = 'Meet and Greet Feedback',
  FinalSurvey = 'Final Survey',
  TermsAndConditionsAgreement = 'Term and Conditions Agreement',
  MidCohortSurvey = 'Mid-Cohort Survey',
  Onboarding = 'Onboarding',
  NewMenteeSurvey = 'New Mentee Survey',
  NewMentorSurvey = 'New Mentor Survey',
  NewCoacheeSurvey = 'New Coachee Survey',
  NewCoachSurvey = 'New Coach Survey'
}

export enum Colors {
  ButtonGreen = '#3C7441',
  Text = '#506657',
  TextTabs = '#445349',
  TextElevated = '#6D8976',
  BackgroundMain = '#F7FCF9',
  BackgroundMainLighter = '#fcfdfc',
  BackgroundDrawer = '#F0F9F1',
  MenuText = '#3C7441',
  SelectedDrawerBackground = '#E8F4E9',
  Active = '#E8F4E9',
  Inactive = '#F1DEE1',
  LogoRed = '#EF3B59'
}

export enum LearningToolStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum Emails {
  MainEmail = 'team@locelle.com'
}

export const STAGE_MAPPING = {
  'Meet And Greet': SessionNotesTemplates.MeetAndGreet,
  'Recurring Session': SessionNotesTemplates.RecurringSession,
  'Final Session': SessionNotesTemplates.FinalSession,
  'One on One Session': SessionNotesTemplates.OneOnOne
};

export enum SessionDurationMinutes {
  Thirty = 30,
  FortyFive = 45,
  Sixty = 60,
  Ninety = 90,
  HundredFive = 105,
  HundredTwenty = 120
}