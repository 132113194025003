import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import VideoDialog from './VideoDialog';
import { Colors } from '../../enums/enums';

const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';

const useStyles = makeStyles({
  root: {
    width: '330px',
    height: '330px',
    marginLeft: '25px',
    marginBottom: '30px',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)', cursor: 'pointer' }
  }
});

export default function VideoMediaCarousel() {
  const classes = useStyles();

  const [data, setData] = useState({ items: [] });
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?playlistId=PLCvABU_CjrRDWBL5SUUvSc20-DYTh9bPD&part=snippet,id&maxResults=40&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
      )
      .then((res) => {
        setData(res.data);
        console.log(data);
      });
  }, []);

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1900 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1900, min: 1500 },
      items: 4
    },
    smallDesktop: {
      breakpoint: { max: 1500, min: 1150 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1150, min: 700 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1
    }
  };

  const handleClose = () => {
    setOpenVideoDialog(false);
  };

  return (
    <Box sx={{ mr: '120px', mt: '30px' }}>
      <Carousel
        containerClass="container"
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        draggable={false}
        autoPlaySpeed={4000}
      >
        {data.items.map((item) => (
          <Card
            key={item.id}
            className={classes.root}
            onClick={() => {
              setVideoId(item?.snippet.resourceId.videoId);
              setOpenVideoDialog(true);
            }}
          >
            <CardMedia
              component="img"
              image={
                item.snippet.thumbnails?.maxres?.url ||
                `https://img.youtube.com/vi/${item?.snippet.resourceId.videoId}/maxresdefault.jpg`
              }
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                VIDEO
              </Typography>
              <Typography gutterBottom variant="h6" component="div" sx={{ color: Colors.TextElevated }}>
                {item.snippet.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Carousel>
      <VideoDialog isOpen={openVideoDialog} handleClose={handleClose} videoId={videoId} />
    </Box>
  );
}