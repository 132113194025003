import React from 'react';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Button
} from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { FormGroup, TextField } from '@mui/material';
import { IFormProps } from '../NewMenteeSurvey';

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.newMentee
  });
  const classes = useStyles();
  const watchEthnic = watch('ethnic');
  const watchPronoun = watch('pronoun');

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 1: ', data);
    actions.updateAction({ formDetails: { newMentee: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Our community is diverse and we are proud of it. We appreciate it if you can share your ethnic origin. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {[
                    'European',
                    'Hispanic/Latinx',
                    'South American',
                    'African',
                    'Indigenous',
                    'White/Caucasian',
                    'Arab',
                    'Asian/South Asian',
                    'Prefer not to answer',
                    'Other'
                  ].map((ethnic, index) => (
                    <FormControlLabel key={index} value={ethnic} control={<Radio color="primary" />} label={ethnic} />
                  ))}
                </RadioGroup>
              )}
              name="ethnic"
              control={control}
              rules={{ required: true }}
            />
            {errors?.ethnic?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchEthnic && watchEthnic?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      fullWidth
                      placeholder="Your answer *"
                      required
                      variant="outlined"
                    />
                  )}
                  name="otherEthnic"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              We are intentional about building a diverse community. We appreciate if you would be willing to share how
              you identify yourself. *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup style={{ marginLeft: 10 }} {...field}>
                  {['She/Her', 'He/Him', 'They/Them', 'Ze/Hir', 'Xe/Xem', 'Prefer not to answer', 'Other'].map(
                    (pronoun, index) => (
                      <FormControlLabel
                        key={index}
                        value={pronoun}
                        control={<Radio color="primary" />}
                        label={pronoun}
                      />
                    )
                  )}
                </RadioGroup>
              )}
              name="pronoun"
              control={control}
              rules={{ required: true }}
            />
            {errors?.pronoun?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
          {watchPronoun && watchPronoun?.includes('Other') && (
            <FormControl>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ m: 1 }}
                      {...field}
                      placeholder="Your answer *"
                      required
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherPronoun"
                  control={control}
                />
              </Grid>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel className={classes.formLabel}>
              What are your specific goals and objectives that you would like to work on during mentorship? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  sx={{ ml: 2 }}
                  multiline
                  rows={4}
                  placeholder="Your answer"
                  fullWidth
                  variant="outlined"
                  {...field}
                />
              )}
              name="goal"
              control={control}
              rules={{ required: true }}
            />
            {errors?.goal?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              What motivated you to seek a mentor, and what do you hope to achieve through this mentorship? *
            </FormLabel>
            <FormGroup>
              {[
                'Accountability',
                'Gain clarity',
                'Having a trusted advisor for ongoing support',
                'Stepping into leadership (non-people management)',
                'Managing a team',
                'Upskill/level up in my field'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('motivation', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="motivation"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.motivation?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.motivation?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Which of the following describes your personality style? (Select all that apply): *
            </FormLabel>
            <FormGroup>
              {[
                'Motivational',
                'Upbeat',
                'Calm',
                'Direct',
                'Realist',
                'Compassionate',
                'Extroverted',
                'Introverted'
              ].map((optionValue, index) => (
                <Grid item xs={12} key={index}>
                  <FormLabel key={optionValue} className={classes.checkbox}>
                    <input
                      {...register('personality', { required: true })}
                      type="checkbox"
                      value={optionValue}
                      name="personality"
                      style={{ margin: '15px', transform: 'scale(1.2)' }}
                    />
                    <span style={{ color: 'black', fontSize: '16px' }}>{optionValue}</span>
                  </FormLabel>
                </Grid>
              ))}
            </FormGroup>
            {errors.personality?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
            <FormHelperText>{errors?.personality?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;
