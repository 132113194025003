import React from 'react';
import { TextField, Grid, FormHelperText, Button } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import { IFormProps } from './section.interface';

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.mentee
  });
  const classes = useStyles();

  const onSubmit = (data: any) => {
    actions.updateAction({ formDetails: { mentee: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid style={{ paddingLeft: '20%', paddingRight: '20%' }} item xs={12}>
          <Controller
            render={({ field }) => <TextField {...field} label="Email address *" fullWidth variant="outlined" />}
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Please enter a valid email' }
            }}
          />
          {errors?.email?.type === 'required' && (
            <FormHelperText className={classes.errorText}>Required</FormHelperText>
          )}
          {errors?.email?.type === 'pattern' && (
            <FormHelperText className={classes.errorText}>{errors.email.message}</FormHelperText>
          )}
        </Grid>

        <Grid style={{ paddingLeft: '20%', paddingRight: '20%' }} item xs={12}>
          <Controller
            render={({ field }) => <TextField {...field} label="Full name *" fullWidth variant="outlined" />}
            name="name"
            control={control}
            rules={{ required: true }}
          />
          {errors?.name?.type === 'required' && <FormHelperText className={classes.errorText}>Required</FormHelperText>}
        </Grid>

        <Grid style={{ paddingLeft: '20%', paddingRight: '20%' }} item xs={12}>
          <Controller
            render={({ field }) => <TextField {...field} label="Company *" fullWidth variant="outlined" />}
            name="company"
            control={control}
            rules={{ required: true }}
          />
          {errors?.company?.type === 'required' && (
            <FormHelperText className={classes.errorText}>Required</FormHelperText>
          )}
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="secondary" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;
